define('lxso/components/option-group/mandatory-fees/component', ['exports', 'lxso/constants/price-types'], function (exports, _priceTypes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    isMandatoryFeesDialogOpen: false,
    mandatoryFees: null,
    isEdit: false,
    removedTicketTypes: [],

    actions: {
      editMandatoryFees: function editMandatoryFees() {
        this.sendAction('editMandatoryFees');
        console.log('Editing mandatory fees'); // Custom logic can be added here
        this.set('isEditMandatoryFees', true);
      },
      cancelEdit: function cancelEdit() {
        console.log('Edit canceled');
        this.set('isEditMandatoryFees', false); // Set isEdit to false to revert to non-editable mode
        // Optionally, you can also reset the model data to its original state here
      },
      openStep: function openStep() {
        this.toggleProperty('isOptionGroupDetailsOpenMF');
      },
      openAdmissionFeesDialog: function openAdmissionFeesDialog() {
        // Implement the logic to open the Admission Fees dialog.
        this.get('openAdmissionFeesDialog')();
      },
      openDockFeesDialog: function openDockFeesDialog() {
        // Implement the logic to open the Admission Fees dialog.
        this.get('openDockFeesDialog')();
      },
      openFuelFeesDialog: function openFuelFeesDialog() {
        // Implement the logic to open the Admission Fees dialog.
        this.get('openFuelFeesDialog')();
      },
      openInsuranceFeesDialog: function openInsuranceFeesDialog() {
        // Implement the logic to open the Admission Fees dialog.
        this.get('openInsuranceFeesDialog')();
      },
      openAccommodationFeesDialog: function openAccommodationFeesDialog() {
        // Implement the logic to open the Admission Fees dialog.
        this.get('openAccommodationFeesDialog')();
      },
      openTransportFeesDialog: function openTransportFeesDialog() {
        // Implement the logic to open the Admission Fees dialog.
        this.get('openTransportFeesDialog')();
      },
      openSoloFeesDialog: function openSoloFeesDialog() {
        // Implement the logic to open the Admission Fees dialog.
        this.get('openSoloFeesDialog')();
      },
      openHolidayFeesDialog: function openHolidayFeesDialog() {
        // Implement the logic to open the Admission Fees dialog.
        this.get('openHolidayFeesDialog')();
      },
      openMiscellaneousFeesDialog: function openMiscellaneousFeesDialog() {
        // Implement the logic to open the Admission Fees dialog.
        this.get('openMiscellaneousFeesDialog')();
      },
      saveAllV4MandatoryFees: function saveAllV4MandatoryFees() {
        console.log('inside saveAllV4MandatoryFees');
        var admissionFees = this.get('admissionFees');
        var dockFees = this.get('dockFees');
        var fuelFees = this.get('fuelFees');
        var insuranceFees = this.get('insuranceFees');
        var accommodationFees = this.get('accommodationFees');
        var transportFees = this.get('transportFees');
        var soloFees = this.get('soloFees');
        var holidayFees = this.get('holidayFees');
        var miscellaneousFees = this.get('miscellaneousFees');

        // Define all removedTicketTypes variables in an array
        var removedTicketTypesArray = [this.get('removedTicketTypesAdmission'), this.get('removedTicketTypesDock'), this.get('removedTicketTypesFuel'), this.get('removedTicketTypesAccommodation'), this.get('removedTicketTypesInsurance'), this.get('removedTicketTypesTransport'), this.get('removedTicketTypesSolo'), this.get('removedTicketTypesHoliday'), this.get('removedTicketTypesMiscellaneous')];

        console.log('this.get(removedTicketTypesAdmission)...,', this.get('removedTicketTypesAdmission'));
        console.log('this.get(removedTicketTypesDock)...,', this.get('removedTicketTypesDock'));

        // Initialize the combined fees array
        var combinedFees = [];

        // If admissionFees does NOT have a ticketTypes array, add it to combinedFees
        if (admissionFees.length && !admissionFees[0].ticketTypes) {
          combinedFees = combinedFees.concat(admissionFees);
          console.log('inside admissionFees....');
        }

        // If dockFees does NOT have a ticketTypes array, add it to combinedFees
        if (dockFees.length && !dockFees[0].ticketTypes) {
          combinedFees = combinedFees.concat(dockFees);
          console.log('inside dockFees....');
        }

        // If dockFees does NOT have a ticketTypes array, add it to combinedFees
        if (fuelFees.length && !fuelFees[0].ticketTypes) {
          combinedFees = combinedFees.concat(fuelFees);
          console.log('inside dockFees....');
        }

        // If dockFees does NOT have a ticketTypes array, add it to combinedFees
        if (insuranceFees.length && !insuranceFees[0].ticketTypes) {
          combinedFees = combinedFees.concat(insuranceFees);
          console.log('inside insuranceFees....');
        }

        // If accommodationFees does NOT have a ticketTypes array, add it to combinedFees
        if (accommodationFees.length && !accommodationFees[0].ticketTypes) {
          combinedFees = combinedFees.concat(accommodationFees);
          console.log('inside accommodationFees....');
        }

        // If accommodationFees does NOT have a ticketTypes array, add it to combinedFees
        if (transportFees.length && !transportFees[0].ticketTypes) {
          combinedFees = combinedFees.concat(transportFees);
          console.log('inside transportFees....');
        }

        // If accommodationFees does NOT have a ticketTypes array, add it to combinedFees
        if (soloFees.length && !soloFees[0].ticketTypes) {
          combinedFees = combinedFees.concat(soloFees);
          console.log('inside soloFees....');
        }

        // If accommodationFees does NOT have a ticketTypes array, add it to combinedFees
        if (holidayFees.length && !holidayFees[0].ticketTypes) {
          combinedFees = combinedFees.concat(holidayFees);
          console.log('inside holidayFees....');
        }

        // If accommodationFees does NOT have a ticketTypes array, add it to combinedFees
        if (miscellaneousFees.length && !miscellaneousFees[0].ticketTypes) {
          combinedFees = combinedFees.concat(miscellaneousFees);
          console.log('inside miscellaneousFees....');
        }

        // Iterate over removedTicketTypesArray to add each non-empty removed ticket type
        removedTicketTypesArray.forEach(function (removedTickets) {
          if (removedTickets && removedTickets.length) {
            console.log('removedTickets inside removedTicketTypesArray foreach:', removedTickets);
            combinedFees = combinedFees.concat(removedTickets);
          }
        });

        this.sendAction('saveV4MandatoryFees', combinedFees); // Call the route's action
      }
    }
  });
});