define('lxso/components/custom-alert-dialog', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      closeAlert: function closeAlert() {
        this.get('onClose')(); // Call the parent action to hide the alert
        window.location.reload();
      }
    }
  });
});