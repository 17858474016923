define('lxso/components/option-group/optional-fees/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    isOptionalFeesDialogOpen: false,
    mandatoryFees: null,

    actions: {
      editOptionalFees: function editOptionalFees() {
        this.sendAction('editOptionalFees');
        console.log('Editing Optional fees'); // Custom logic can be added here
        this.set('isEditOptionalFees', true);
      },
      cancelEditOptionalFees: function cancelEditOptionalFees() {
        console.log('EditOptionalFees canceled');
        this.set('isEditOptionalFees', false); // Set isEdit to false to revert to non-editable mode
      },
      openStep: function openStep() {
        this.toggleProperty('isOptionGroupOpenOptionalFees');
      },
      openEquipmentFeesDialog: function openEquipmentFeesDialog() {
        // Implement the logic to open the Admission Fees dialog.
        this.get('openEquipmentFeesDialog')();
      },
      openPhotographyFeesDialog: function openPhotographyFeesDialog() {
        // Implement the logic to open the Admission Fees dialog.
        this.get('openPhotographyFeesDialog')();
      },
      openMarriageFeesDialog: function openMarriageFeesDialog() {
        // Implement the logic to open the Admission Fees dialog.
        this.get('openMarriageFeesDialog')();
      },
      openLicenceFeesDialog: function openLicenceFeesDialog() {
        // Implement the logic to open the Admission Fees dialog.
        this.get('openLicenceFeesDialog')();
      },
      openTastingFeesDialog: function openTastingFeesDialog() {
        // Implement the logic to open the Admission Fees dialog.
        this.get('openTastingFeesDialog')();
      },
      openCustomsFeesDialog: function openCustomsFeesDialog() {
        // Implement the logic to open the Admission Fees dialog.
        this.get('openCustomsFeesDialog')();
      },
      openVisaFeesDialog: function openVisaFeesDialog() {
        // Implement the logic to open the Admission Fees dialog.
        this.get('openVisaFeesDialog')();
      },
      openGovernmentFeesDialog: function openGovernmentFeesDialog() {
        // Implement the logic to open the Admission Fees dialog.
        this.get('openGovernmentFeesDialog')();
      },
      saveAllV4OptionalFees: function saveAllV4OptionalFees() {
        console.log('inside saveAllV4OptionalFees');
        var equipmentFees = this.get('equipmentFees');
        var photographyFees = this.get('photographyFees');
        var marriageFees = this.get('marriageFees');
        var licenceFees = this.get('licenceFees');
        var tastingFees = this.get('tastingFees');
        var customsFees = this.get('customsFees');
        var visaFees = this.get('visaFees');
        var governmentFees = this.get('governmentFees');

        var removedTicketTypesArrayOptional = [this.get('removedTicketTypesEquipment'), this.get('removedTicketTypesPhotography'), this.get('removedTicketTypesMarriage'), this.get('removedTicketTypesLicence'), this.get('removedTicketTypesTasting'), this.get('removedTicketTypesCustoms'), this.get('removedTicketTypesVisa'), this.get('removedTicketTypesGovernment')];

        // Initialize the combined fees array
        var combinedFees = [];

        // If equipmentFees does NOT have a ticketTypes array, add it to combinedFees
        if (equipmentFees.length && !equipmentFees[0].ticketTypes) {
          combinedFees = combinedFees.concat(equipmentFees);
          console.log('inside equipmentFees....');
        }

        // If photographyFees does NOT have a ticketTypes array, add it to combinedFees
        if (photographyFees.length && !photographyFees[0].ticketTypes) {
          combinedFees = combinedFees.concat(photographyFees);
          console.log('inside photographyFees....');
        }

        // If marriageFees does NOT have a ticketTypes array, add it to combinedFees
        if (marriageFees.length && !marriageFees[0].ticketTypes) {
          combinedFees = combinedFees.concat(marriageFees);
          console.log('inside marriageFees....');
        }

        // If licenceFees does NOT have a ticketTypes array, add it to combinedFees
        if (licenceFees.length && !licenceFees[0].ticketTypes) {
          combinedFees = combinedFees.concat(licenceFees);
          console.log('inside licenceFees....');
        }

        // If tastingFees does NOT have a ticketTypes array, add it to combinedFees
        if (tastingFees.length && !tastingFees[0].ticketTypes) {
          combinedFees = combinedFees.concat(tastingFees);
          console.log('inside tastingFees....');
        }

        // If customsFees does NOT have a ticketTypes array, add it to combinedFees
        if (customsFees.length && !customsFees[0].ticketTypes) {
          combinedFees = combinedFees.concat(customsFees);
          console.log('inside customsFees....');
        }

        // If visaFees does NOT have a ticketTypes array, add it to combinedFees
        if (visaFees.length && !visaFees[0].ticketTypes) {
          combinedFees = combinedFees.concat(visaFees);
          console.log('inside visaFees....');
        }

        // If governmentFees does NOT have a ticketTypes array, add it to combinedFees
        if (governmentFees.length && !governmentFees[0].ticketTypes) {
          combinedFees = combinedFees.concat(governmentFees);
          console.log('inside governmentFees....');
        }

        // Iterate over removedTicketTypesArray to add each non-empty removed ticket type
        removedTicketTypesArrayOptional.forEach(function (removedTickets) {
          if (removedTickets && removedTickets.length) {
            console.log('removedTickets inside removedTicketTypesArray foreach:', removedTickets);
            combinedFees = combinedFees.concat(removedTickets);
          }
        });

        this.sendAction('saveV4OptionalFees', combinedFees);
      }
    }
  });
});