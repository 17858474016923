define('lxso/controllers/activity/mandatory-fees-upload', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    jsXlsx: Ember.inject.service(),
    isUploadDialogOpen: false, // Initialize the dialog state
    isAlertVisible: false, // Initialize alert visibility
    alertMessage: '',

    createCsvAndDownload: function createCsvAndDownload(data, fileName) {
      this.get('jsXlsx').createXlsxFile(data, 'additionalFees', fileName);
    },


    actions: {
      openUploadDialog: function openUploadDialog() {
        this.set('isUploadDialogOpen', true); // Open the dialog
      },
      showAlert: function showAlert(message) {
        console.log('Alert:', message);
        // You can add custom alert logic here
        this.set('alertMessage', message);
        this.set('isAlertVisible', true);
        this.notifyPropertyChange('isAlertVisible');
      },
      hideAlert: function hideAlert() {
        this.set('isAlertVisible', false);
      },
      downloadSampleCSV: function downloadSampleCSV() {
        var sampleData = [{ ActivityId: 191539, FeeTypeId: 5, Mandatory: 1, StartDate: '2024-10-16',
          EndDate: '2025-12-26', Amount: 100, BookingType: 'Adult' }];
        var fileName = 'additional-fees-sample.csv';
        this.createCsvAndDownload(sampleData, fileName);
      }
    }
  });
});