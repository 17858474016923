define('lxso/components/option-group/optional-fees/licence-fees/component', ['exports', 'lxso/utils/ticket-type-validator'], function (exports, _ticketTypeValidator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Component.extend({

    licenceFees: null,
    selectedTicketTypes: Ember.A(),
    offerIds: null,
    activityId: null,
    removedTicketTypesLicence: [],

    init: function init() {
      this._super.apply(this, arguments);
      console.log('Inside init of licenceFees-dialog.js');
      var offerIds = this.get('offers').map(function (offer) {
        return offer.get('offerIds');
      }).flat();
      this.set('offerIds', offerIds);

      var activityId = this.get('activityId');
      this.set('activityId', activityId);

      // Initialize selectedTicketTypes based on prefilled values if licenceFees are available
      var licenceFees = this.get('licenceFees');
      if (licenceFees && licenceFees.length > 0) {
        var ticketTypes = licenceFees[0].ticketTypes || Ember.A();
        var selectedTypes = ticketTypes.map(function (type) {
          return {
            amount: type.amount,
            selectedOption: type.ticketTypeName
          };
        });

        this.set('selectedTicketTypes', Ember.A(selectedTypes));
      } else {
        // Initialize with empty ticket type if no licenceFees are present
        this.set('selectedTicketTypes', Ember.A([{ amount: null, selectedOption: '' }]));
      }
    },


    // for prefilling the form

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      console.log('Inside didReceiveAttrs of licenceFees-dialog.js');

      // If licenceFees is provided, use its values to prefill the form
      var licenceFees = this.get('licenceFees') || [];
      console.log('licenceFees inside didReceiveAttrs', licenceFees);
      console.log('licenceFees.length inside didReceiveAttrs', licenceFees.length);

      if (licenceFees.length === 1 && licenceFees[0].ticketTypes) {
        var firstFee = licenceFees[0];
        console.log('firstFee inside didReceiveAttrs', firstFee);

        // Map the ticket types from the response
        var editingTicketTypes = firstFee.ticketTypes.map(function (ticketType) {
          return {
            amount: ticketType.amount,
            selectedOption: ticketType.ticketType,
            selectedTicketTypeId: ticketType.ticketTypeId,
            additionalFeeId: ticketType.additionalFeeId,
            mandatory: ticketType.mandatory,
            status: ticketType.status
          };
        });

        console.log('editingTicketTypes in component js', editingTicketTypes);

        // Set the description, startDate, endDate, and ticketTypes using the first admission fee
        this.setProperties({
          description: firstFee.description || '',
          startDate: firstFee.startDate || '',
          endDate: firstFee.endDate || '',
          additionalFeeTypesID: firstFee.additionalFeeTypesID || '',
          additionalFeeDescription: firstFee.additionalFeeDescription || '',
          editingTicketTypes: Ember.A(editingTicketTypes) // Set the ticket types and amounts
        });
      } else if (licenceFees.length > 0 && !licenceFees[0].ticketTypes) {
        var _firstFee = licenceFees[0];
        console.log('firstFee inside else if didReceiveAttrs', _firstFee);

        var _editingTicketTypes = licenceFees.map(function (fee) {
          return {
            amount: fee.amount,
            selectedOption: fee.ticketType, // or another suitable label for the ticket type
            selectedTicketTypeId: fee.ticketTypeId,
            additionalFeeId: fee.additionalFeeId,
            mandatory: fee.mandatory,
            status: fee.status
          };
        });

        console.log('editingTicketTypes inside else if didReceiveAttrs', _editingTicketTypes);

        this.setProperties({
          description: _firstFee.description || '',
          startDate: _firstFee.startDate || '',
          endDate: _firstFee.endDate || '',
          additionalFeeTypesID: _firstFee.additionalFeeTypesID || '',
          additionalFeeDescription: _firstFee.additionalFeeDescription || '',
          editingTicketTypes: Ember.A(_editingTicketTypes) // Set the ticket types and amounts for all fees
        });
      } else {
        // Default values if no admission fees provided
        this.setProperties({
          description: '',
          startDate: '',
          endDate: '',
          editingTicketTypes: Ember.A([{ amount: null, selectedOption: '' }])
        });
      }
    },


    actions: {
      addTicketType: function addTicketType() {
        this.get('editingTicketTypes').pushObject({ amount: null, selectedOption: '' });
      },
      removeTicketType: function removeTicketType(ticketType) {
        if (!ticketType) {
          console.error('No ticketType provided for removal.');
        }

        // Log the ticketType being removed for debugging
        console.log('Removing ticketType:', ticketType);

        this.get('removedTicketTypesLicence').push(ticketType);

        var updatedTicketTypes = this.get('editingTicketTypes').filter(function (item, index) {
          console.log('item.additionalFeeId', item.additionalFeeId);
          return item.additionalFeeId !== ticketType.additionalFeeId;
        });

        // Update the state with the filtered list
        this.set('editingTicketTypes', updatedTicketTypes);
        console.log('Updated ticketTypes:', updatedTicketTypes);
        console.log('this.get(removedTicketTypesLicence):', this.get('removedTicketTypesLicence'));
      },
      handleTicketTypeChange: function handleTicketTypeChange(index, selectedValue) {
        var editingTicketTypes = this.get('editingTicketTypes'); // Get the ticket types
        var ticketTypeDisplayNames = this.get('ticketTypeDisplayNames'); // Get the display names

        // Validate when ticket type is changed
        (0, _ticketTypeValidator.default)(editingTicketTypes, ticketTypeDisplayNames, index, selectedValue);

        // Additional logic if needed when the ticket type changes
        console.log('Handling ticket type change for index ' + index + ', selected value: ' + selectedValue);
      },
      save: function save() {
        var offerIds = this.get('offerIds');

        // Conditionally set offerId based on the size of offerIds array
        var offerId = void 0;
        if (offerIds.length === 1) {
          offerId = offerIds[0]; // Set as a single value if only one offerId exists
        } else {
          offerId = offerIds; // Set as an array if more than one offerId exists
        }

        var activityId = this.get('activityId');

        console.log('activityId....', activityId);
        console.log('offerId....', offerId);

        var applyToAllOffers = this.get('applyToAllOffers');
        console.log('applyToAllOffers', applyToAllOffers);

        var additionalFeeTypesID = this.get('additionalFeeTypesID');
        console.log('additionalFeeTypesID inside save method', additionalFeeTypesID);

        var commonFields = {
          description: this.get('description'),
          startDate: this.get('startDate'),
          endDate: this.get('endDate'),
          additionalFeeDescription: this.get('additionalFeeDescription'),
          additionalFeeTypesID: this.get('additionalFeeTypesID'),
          offerId: offerId,
          activityId: activityId,
          mandatory: this.get('mandatory')
        };

        console.log('editingTicketTypes inside save', this.get('editingTicketTypes'));

        // Iterate over ticketTypes and create separate objects for each
        var licenceFees = this.get('editingTicketTypes').filter(function (ticket) {
          return ticket.selectedOption !== '';
        }) // Filter out tickets without a selected option
        .map(function (ticketType) {
          var ticketTypeId = ticketType.selectedTicketTypeId;
          var additionalFeeId = ticketType.additionalFeeId;
          var mandatory = ticketType.mandatory;
          var status = ticketType.status;
          console.log('Mapped ticketTypeId:', ticketTypeId);
          console.log('Ticket Type:', ticketType);
          console.log('additionalFeeId inside save:', additionalFeeId);

          if (!ticketTypeId) {
            console.error('Ticket Type ID is missing for', ticketType);
          }

          return Object.assign({}, commonFields, {
            amount: ticketType.amount || '0.000000', // Set default amount if missing
            ticketTypeId: ticketTypeId, // Use the mapped ticketTypeId
            additionalFeeId: additionalFeeId,
            mandatory: mandatory,
            status: status,
            ticketType: ticketType.selectedOption
          });
        });

        // Handling removed ticket types
        var removedTicketTypesLicence = this.get('removedTicketTypesLicence').map(function (ticket) {
          return Object.assign({}, commonFields, {
            ticketTypeId: ticket.selectedTicketTypeId,
            additionalFeeId: ticket.additionalFeeId,
            amount: ticket.amount || '0.000000',
            status: 'D', // Set status to "D" for deleted
            mandatory: ticket.mandatory,
            ticketType: ticket.selectedOption
          });
        });

        // Combine dockFees and removedTicketTypes
        var finalFeesToSave = [].concat(_toConsumableArray(licenceFees), _toConsumableArray(removedTicketTypesLicence));
        console.log('removedTicketTypesLicence in component save', removedTicketTypesLicence);
        console.log('finalFeesToSave in component save', finalFeesToSave);

        this.get('onSave')(licenceFees, removedTicketTypesLicence);
        console.log('licenceFees in component save', licenceFees);
      },
      close: function close() {
        console.log('Inside close of licenceFees-dialog.js');
        this.get('onClose')();
      }
    }
  });
});