define('lxso/helpers/currentDateFormat', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function () {
    var today = new Date();
    var year = today.getFullYear();
    var month = String(today.getMonth() + 1).padStart(2, '0'); // Ensure two digits
    var day = String(today.getDate()).padStart(2, '0'); // Ensure two digits
    return year + '-' + month + '-' + day;
  });
});