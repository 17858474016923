define('lxso/routes/activity/offer', ['exports', 'lxso/mixins/publish-api', 'lxso/mixins/example-modal', 'lxso/mixins/multi-offer-support', 'lxso/utils/array-values', 'lxso/utils/form-validator', 'lxso/config/environment', 'lxso/utils/omniture', 'lodash/array', 'lxso/utils/array-values-higher', 'lxso/utils/offer-group', 'lxso/utils/to-percent-without-percent-sign'], function (exports, _publishApi, _exampleModal, _multiOfferSupport, _arrayValues, _formValidator, _environment, _omniture, _array2, _arrayValuesHigher, _offerGroup, _toPercentWithoutPercentSign) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_exampleModal.default, _multiOfferSupport.default, _publishApi.default, {
    session: Ember.inject.service(),
    permissions: Ember.inject.service(),
    fileSaver: Ember.inject.service(),
    i18n: Ember.inject.service(),
    languageEnum: Ember.inject.service(),
    netPrice: Ember.inject.service(),
    ajaxWrapper: Ember.inject.service(),
    responseErrors: Ember.inject.service(),
    featureFlags: Ember.inject.service(),
    nameInHeader: Ember.inject.service(),
    staticData: Ember.inject.service(),
    queryParams: {
      clone: {
        refreshModel: true
      },
      isOfferGroup: {
        refreshModel: true
      }
    },
    omniture: {
      pageName: 'page.LX-Supply.CreateEditOption'
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        var offer = controller.get('offer');
        if (offer && offer.get('hasDirtyAttributes')) {
          offer.rollbackAttributes();
        }

        controller.set('clone', undefined);
        controller.set('offer.editingSetup', false);
        controller.set('offer.pricesOpen', false);
        controller.set('offer.setupOpen', false);
        controller.set('offer.mandatoryFeesOpen', false);
        controller.set('offer.optionalFeesOpen', false);
        controller.set('editingPg', false);
        controller.set('generatedDescriptionString', undefined);
        controller.set('offerPostfix', undefined);
        controller.set('mealString', undefined);
        controller.set('ticketTypes', undefined);
        controller.set('usageDaysCheckboxClicked', false);
        controller.set('hasNewOffers', false);
        controller.set('updatedOffer', true);
        controller.set('offer.mandatoryFeeEditingSetup', false);
        controller.set('offer.optionalFeeEditingSetup', false);
      }
    },
    cloneOffer: function cloneOffer(existing, isOfferGroup) {
      var defaultOffer = {
        shortTitle: existing.get('shortTitle'),
        description: existing.get('description'),
        durationInMinutes: existing.get('durationInMinutes'),
        isLimitedInventory: existing.get('isLimitedInventory'),
        bookingCutoffInHours: existing.get('bookingCutoffInHours'),
        cancellationPolicyType: existing.get('cancellationPolicyType'),
        cancellationCutoffInHours: existing.get('cancellationCutoffInHours'),
        offerAttributes: {
          mealType: existing.get('offerAttributes.mealType'),
          startTime: existing.get('offerAttributes.startTime'),
          endTime: existing.get('offerAttributes.endTime'),
          scheduleType: existing.get('offerAttributes.scheduleType'),
          languageInfo: {
            languageTypeId: existing.get('offerAttributes.languageInfo.languageTypeId'),
            languageIDs: existing.get('offerAttributes.languageInfo.languageIDs')
          }
        }
      };

      defaultOffer.offerAttributes.isPriceByVolume = existing.get('offerAttributes.isPriceByVolume');
      defaultOffer.offerAttributes.isPricingIngestion = existing.get('offerAttributes.isPricingIngestion');
      var isMultiOfferGroupEnabled = this.get('featureFlags').checkEnabled('MultipleOptionsGroup');
      var existingScheduleType = existing.get('offerAttributes.scheduleType');

      if (isMultiOfferGroupEnabled) {
        if (isOfferGroup) {
          defaultOffer.offerDetails = existing.get('offerDetails');
        } else if (existingScheduleType.toLowerCase() === 'start' || this.get('featureFlags').checkEnabled('MultipleOptionsGroupAllSchedules')) {
          var offerDetail = {
            startTime: existing.get('offerAttributes.startTime') ? existing.get('offerAttributes.startTime') : '',
            endTime: existing.get('offerAttributes.endTime') ? existing.get('offerAttributes.endTime') : '',
            status: 'active',
            durationInMinutes: existing.get('durationInMinutes'),
            languageInfo: {
              languageTypeId: existing.get('offerAttributes.languageInfo.languageTypeId'),
              languageIDs: existing.get('offerAttributes.languageInfo.languageIDs')
            }
          };
          defaultOffer.offerDetails = [offerDetail];
        }
      }

      return this.store.createRecord('offer', defaultOffer);
    },
    model: function model(param, transition) {
      var _this = this;

      var activityId = transition.params.activity.id;
      var ticketTypes = this.store.peekRecord('activity', activityId).get('ticketTypes');
      var offer = void 0;
      if (param.offerId === 'new') {
        offer = this.store.createRecord('offer');
      } else if (param.clone) {
        if (param.isOfferGroup === 'true') {
          offer = this.store.findRecord('offer', param.offerId, { reload: true, adapterOptions: { isOfferGroup: true } }).then(function (offerToBeCloned) {
            return _this.cloneOffer(offerToBeCloned, true);
          });
        } else {
          offer = this.store.findRecord('offer', param.offerId, { reload: true }).then(function (offerToBeCloned) {
            return _this.cloneOffer(offerToBeCloned, false);
          });
        }
      } else {
        if (param.isOfferGroup === 'true') {
          offer = this.store.findRecord('offer', param.offerId, { reload: true, adapterOptions: { isOfferGroup: true } });
        } else {
          offer = this.store.findRecord('offer', param.offerId, { reload: true });
        }
      }
      console.log('offer here is: ', offer);

      var languages = this.get('languageEnum').getLanguages();
      var platformId = this.get('session.session.authenticated.selectedBranch.platform_id');
      var platform = void 0;
      if (platformId) {
        platform = this.store.findRecord('platform', platformId, { reload: true });
      }
      var additionalFees = this.getAdditionalFees(activityId, param.offerId);
      var ticketTypeByActivity = this.getTicketTypeByActivity(activityId);
      var currencyDetails = this.getCurrencyDetails(activityId);
      console.log('ticketTypeByActivity', ticketTypeByActivity);
      console.log('currencyDetails', currencyDetails);
      return Ember.RSVP.hash({
        ticketTypes: ticketTypes,
        offer: offer,
        languages: languages,
        platform: platform,
        externalReference: this.getExternalReference(param, offer),
        param: param,
        activityId: activityId,
        additionalFees: additionalFees,
        ticketTypeByActivity: ticketTypeByActivity,
        currencyDetails: currencyDetails
      });
    },
    afterModel: function afterModel(model) {
      if (this.get('featureFlags').checkEnabled('MultipleOptionsGroup') && model.offer.get('isMultipleOptionGroup') && !(model.param.isOfferGroup === 'true')) {
        this.transitionTo('activity.offer-list', model.activityId);
      }
    },
    getAdditionalFees: function getAdditionalFees(activityId, offerId) {
      // Construct the URL for the mandatory fees API call
      var mandatoryFeesUrl = _environment.default.api.hostV4 + '/import-additional-fees/additional-fee-by-offer-id?' + ('offerId=' + offerId);

      // Use ajaxGet to call the API
      return this.get('ajaxWrapper').ajaxGet(mandatoryFeesUrl).then(function (data) {
        var additionalFees = {
          admissionFees: [],
          dockFees: [],
          fuelFees: [],
          insuranceFees: [],
          accommodationFees: [],
          transportFees: [],
          soloFees: [],
          holidayFees: [],
          miscellaneousFees: [],
          equipmentFees: [],
          photographyFees: [],
          marriageFees: [],
          licenceFees: [],
          tastingFees: [],
          customsFees: [],
          visaFees: [],
          governmentFees: []
        };

        // Process the API response

        data.res.forEach(function (fee) {
          if (fee.additionalFeeDescription === 'Admission Fees') {
            additionalFees.admissionFees.push(fee);
          } else if (fee.additionalFeeDescription === 'Dock or Port Fees/Taxes') {
            additionalFees.dockFees.push(fee);
          } else if (fee.additionalFeeDescription === 'Fuel Charges') {
            additionalFees.fuelFees.push(fee);
          } else if (fee.additionalFeeDescription === 'Insurance / Damage Waiver Charges') {
            additionalFees.insuranceFees.push(fee);
          } else if (fee.additionalFeeDescription === 'Accommodation') {
            additionalFees.accommodationFees.push(fee);
          } else if (fee.additionalFeeDescription === 'Transport / Parking') {
            additionalFees.transportFees.push(fee);
          } else if (fee.additionalFeeDescription === 'Solo') {
            additionalFees.soloFees.push(fee);
          } else if (fee.additionalFeeDescription === 'Holiday Peak') {
            additionalFees.holidayFees.push(fee);
          } else if (fee.additionalFeeDescription === 'Miscellaneous / Other') {
            additionalFees.miscellaneousFees.push(fee);
          } else if (fee.additionalFeeDescription === 'Equipment') {
            additionalFees.equipmentFees.push(fee);
          } else if (fee.additionalFeeDescription === 'Photography') {
            additionalFees.photographyFees.push(fee);
          } else if (fee.additionalFeeDescription === 'Marriage') {
            additionalFees.marriageFees.push(fee);
          } else if (fee.additionalFeeDescription === 'Licence') {
            additionalFees.licenceFees.push(fee);
          } else if (fee.additionalFeeDescription === 'Tasting Fees') {
            additionalFees.tastingFees.push(fee);
          } else if (fee.additionalFeeDescription === 'Customs Fees') {
            additionalFees.customsFees.push(fee);
          } else if (fee.additionalFeeDescription === 'Visa / Entry Fees') {
            additionalFees.visaFees.push(fee);
          } else if (fee.additionalFeeDescription === 'Government Fees / Taxes') {
            additionalFees.governmentFees.push(fee);
          }
        });

        console.log('additionalFees api response', additionalFees);

        return additionalFees; // Return the formatted fees object
      }).catch(function (error) {
        if (error.status === 500) {
          console.error('Server Error (500):', error);
        } else {
          console.error('Error fetching mandatory fees:', error);
        }
        return [];
      });
    },
    getTicketTypeByActivity: function getTicketTypeByActivity(activityId) {
      // Construct the URL for the mandatory fees API call
      var ticketTypeByActivityUrl = _environment.default.api.hostV4 + '/import-additional-fees/' + activityId + '/ticket_types';
      console.log('ticketTypeByActivityUrl', ticketTypeByActivityUrl);

      // Use ajaxGet to call the API
      return this.get('ajaxWrapper').ajaxGet(ticketTypeByActivityUrl).then(function (data) {
        // Filter active ticket types
        console.log('123"', data.res.filter(function (type) {
          return type.status === 'A';
        }));
        return data.res.filter(function (type) {
          return type.status === 'A';
        });
      }).catch(function (error) {
        console.error('Failed to fetch ticket types:', error);
        return []; // Return an empty array on failure
      });
    },
    getCurrencyDetails: function getCurrencyDetails(activityId) {
      var getCurrencyDetailsUrl = _environment.default.api.hostV4 + '/import-additional-fees/' + activityId + '/supplierCurrencyDetail';
      console.log('getCurrencyDetails URL', getCurrencyDetailsUrl);

      return this.get('ajaxWrapper').ajaxGet(getCurrencyDetailsUrl).then(function (data) {
        return data;
      }).catch(function (error) {
        console.error('Failed to fetch supplier Currencies: ', error);
        return []; // Return an empty array on failure
      });
    },
    getExternalReference: function getExternalReference(param, offer) {
      var _this2 = this;

      if (param.offerId === 'new' || param.offerId === 'clone') {
        return;
      } else {
        var externalReferenceUrl = void 0;
        return offer.then(function (offer) {
          var offerDetails = offer.get('offerDetails');
          if (offer.get('isMultipleOptionGroup') === true && offerDetails && offerDetails.length && param.offerId != 'new' && param.offerId != 'clone') {
            var offerId = offerDetails[0].offerId;
            externalReferenceUrl = _environment.default.api.host + '/' + _environment.default.api.namespace + '/offers/' + offerId + '/external_references';
          } else {
            externalReferenceUrl = _environment.default.api.host + '/' + _environment.default.api.namespace + '/offers/' + param.offerId + '/external_references';
          }
          return _this2.get('ajaxWrapper').ajaxGet(externalReferenceUrl);
        });
      }
    },
    setupController: function setupController(ctrl, _ref) {
      var _this3 = this;

      var ticketTypes = _ref.ticketTypes,
          offer = _ref.offer,
          languages = _ref.languages,
          platform = _ref.platform,
          externalReference = _ref.externalReference,
          param = _ref.param,
          additionalFees = _ref.additionalFees,
          ticketTypeByActivity = _ref.ticketTypeByActivity,
          currencyDetails = _ref.currencyDetails;

      this.controller.set('ticketTypes', ticketTypes);
      this.controller.set('hasSavedPriceGroups', offer.get('prices').filter(function (p) {
        return !!p.get('id');
      }).length > 0);
      this.controller.set('activity', this.controllerFor('activity').get('activity'));
      this.controller.set('daysOfWeek', ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']);
      this.controller.set('offer', offer);
      this.controller.set('param', param);
      this.controller.set('platform', platform);
      this.controller.set('usageDaysCheckboxClicked', false);
      this.controller.set('offerStatus', offer.get('status'));
      this.controller.set('updatedOffer', false);
      this.controller.set('displayCancellationWorseType', false);
      this.controller.set('displayCancellationWorse', false);
      var offerIdForMarginFetch = this.getOfferIdForMarginAndExternalReference(offer);
      this.controller.set('offerIdForMarginFetch', offerIdForMarginFetch);
      this.controller.set('usageDaysSupportedPlatformIds', this.get('staticData').getUsageDaysSupportedPlatformIds());
      if (offerIdForMarginFetch) {
        this.get('netPrice').load(offerIdForMarginFetch);
      }

      this.setupUserPermissions();
      this.setupOfferTitle();
      this.setupPriceGroup();
      this.setupSchedule();
      this.setupScheduleTypeDropdown();
      this.setupDaysDropdown();
      this.setupHoursDropdown();
      this.setupCapacityTypeDropdown();
      this.setupCancellationTypeDropdown();
      this.setupMealTypeDropdown();
      this.setupLanguage(languages);
      this.setupDescription();
      this.setPlatformName(externalReference);
      this.setPricingIngestion();
      this.initMultipleOptionSetup(ctrl);
      this.setPreviousCancellationHoursCutoff();
      this.controller.initPaginateSourceArray('offer.prices');
      // New logic to handle mandatoryFees (admissionFees and dockFees)
      this.controller.set('admissionFees', additionalFees.admissionFees || []);
      this.controller.set('dockFees', additionalFees.dockFees || []);
      this.controller.set('fuelFees', additionalFees.fuelFees || []);
      this.controller.set('insuranceFees', additionalFees.insuranceFees || []);
      this.controller.set('accommodationFees', additionalFees.accommodationFees || []);
      this.controller.set('transportFees', additionalFees.transportFees || []);
      this.controller.set('soloFees', additionalFees.soloFees || []);
      this.controller.set('holidayFees', additionalFees.holidayFees || []);
      this.controller.set('miscellaneousFees', additionalFees.miscellaneousFees || []);
      this.controller.set('equipmentFees', additionalFees.equipmentFees || []);
      this.controller.set('photographyFees', additionalFees.photographyFees || []);
      this.controller.set('marriageFees', additionalFees.marriageFees || []);
      this.controller.set('licenceFees', additionalFees.licenceFees || []);
      this.controller.set('tastingFees', additionalFees.tastingFees || []);
      this.controller.set('customsFees', additionalFees.customsFees || []);
      this.controller.set('visaFees', additionalFees.visaFees || []);
      this.controller.set('governmentFees', additionalFees.governmentFees || []);
      this.controller.set('ticketTypeByActivity', ticketTypeByActivity);
      this.controller.set('currencyDetails', currencyDetails);
      this.controller.set('isInternalUser', this.get('featureFlags').checkEnabled('CA_Pricing'));
      console.log('isInternalUser....', this.get('featureFlags').checkEnabled('CA_Pricing'));
      Ember.run.next(function () {
        _this3.setupStep();
        _this3.disableFields();
        _this3.setFieldsForGroundTransfer();
      });

      Ember.run.later('afterRender', function () {
        CKEDITOR.on('instanceReady', function () {
          var isNew = _this3.controller.get('offer.isNew');
          if (!isNew) {
            CKEDITOR.instances['lx-ckeditor textarea-offer-description'].setReadOnly(true);
          }
        });
      });
    },
    setPreviousCancellationHoursCutoff: function setPreviousCancellationHoursCutoff() {
      var hourCutOff = this.controller.get('offer.cancellationCutoffInHours');
      this.controller.set('prevCancellationCutOffHours', hourCutOff);
      var cancellationType = this.controller.get('offer.cancellationPolicyType');
      this.controller.set('prevCancellationType', cancellationType);
    },
    setPricingIngestion: function setPricingIngestion() {
      var supplierConnectivityStatus = this.get('session').get('data.authenticated.selectedBranch.connectivity_status');
      var platformId = this.get('session').get('data.authenticated.selectedBranch.platform_id');
      if (supplierConnectivityStatus === undefined || supplierConnectivityStatus === false) {
        this.controller.set('connectedSupplier', false);
      } else {
        this.controller.set('connectedSupplier', true);
        this.controller.set('offer.platformId', platformId);
      }
      if (this.controller.get('offer.offerAttributes.isPricingIngestion')) {
        var pricingIngestionFlag = this.controller.get('offer.offerAttributes.isPricingIngestion');
        this.controller.set('isPricingIngestion', pricingIngestionFlag);
      } else {
        this.controller.set('isPricingIngestion', false);
      }
    },
    setupUserPermissions: function setupUserPermissions() {
      var permission = 'EditV3OfferAttributes';
      this.set('EditV3OfferAttributesEnabled', this.get('permissions').checkEnabled(permission));
    },
    setPlatformName: function setPlatformName(externalRefResponse) {
      var offerID = this.controller.get('offer.id');
      if (offerID && externalRefResponse) {
        this.get('nameInHeader').setPartnerActivityOrOfferId(externalRefResponse, externalRefResponse.partnerOfferId);
        this.controller.set('offerExternalRefRes', externalRefResponse);
      }
    },
    setupOfferTitle: function setupOfferTitle() {
      var shortTitle = this.controller.get('offer.shortTitle');
      var mealType = this.controller.get('offer.offerAttributes.mealType');
      var scheduleType = this.controller.get('offer.offerAttributes.scheduleType');
      var durationInMinutes = this.controller.get('offer.durationInMinutes');
      var startTime = this.controller.get('offer.offerAttributes.startTime');

      if (shortTitle === undefined) {
        this.controller.set('offer.shortTitle', this.controller.get('activity.basic.long_title'));
      }

      if (mealType) {
        var mealString = mealType === 'Not applicable' ? '' : mealType;
        this.controller.set('mealString', mealString);
      }

      if (this.controller.get('clone')) {
        this._updateTitlePrefix();
      } else {
        this.controller.set('titlePrefix', '--:-- --');
      }

      this.generateOfferString();
    },
    getSelectedLanguageString: function getSelectedLanguageString(selectedLanguageIDs, languageArray) {
      var selectedLanguageStrings = new Ember.A([]);
      for (var i = 0; i < languageArray.length; i++) {
        if (selectedLanguageIDs && selectedLanguageIDs.includes(languageArray[i].id)) {
          selectedLanguageStrings.pushObject(languageArray[i].label);
        }
      }
      return selectedLanguageStrings;
    },
    setupDescription: function setupDescription() {
      var offerDescription = this.controller.get('offer.description');
      var generatedDescription = this.controller.get('generatedDescriptionString');

      if (!offerDescription) {
        this.controller.set('offer.description', '');
      }

      // Sets value of generatedDescription so that if we can find/replace
      // later on edit if we need to
      if (!generatedDescription) {
        var newDescription = '';
        if (offerDescription) {
          var languageTypeId = this.controller.get('offer.offerAttributes.languageInfo.languageTypeId');
          if (languageTypeId !== 3) {
            var languageIDs = this.controller.get('offer.offerAttributes.languageInfo.languageIDs');
            var languageStringArray = this.generateLanguageStringArray(languageIDs);
            newDescription = this.generateDescriptionString(languageTypeId, languageStringArray);
          }
        }
        this.controller.set('generatedDescriptionString', newDescription);
      }
    },
    setupStep: function setupStep() {
      var isNew = this.controller.get('offer.isNew');
      var savedNewOffer = this.controller.get('savedNewOffer');
      if (!isNew) {
        this.send('openStep', 'setup-prices');
      }
      if (!savedNewOffer) {
        this.send('openStep', 'setup-offer');
      } else {
        this.controller.set('savedNewOffer', false);
      }
    },
    setupScheduleTypeDropdown: function setupScheduleTypeDropdown() {
      var isNew = this.controller.get('offer.isNew');
      var type = this.controller.get('offer.offerAttributes.scheduleType');
      var isGroundTransfer = this.controller.get('activity.isViewOnlyForGroundTransfer');
      var typeTranslation = void 0;
      switch (type) {
        case 'Valid Days':
          typeTranslation = 'validDays';
          break;
        case 'Valid Hours':
          typeTranslation = 'validHours';
          break;
        case 'Hours of Operation':
          typeTranslation = 'operatingHours';
          break;
        case 'Start':
          this.controller.set('selectedDays', 0);
          break;
      }

      this.setScheduleSupertype(type);

      var scheduleTypeLabels = void 0;
      var scheduleTypeValues = void 0;
      var startKey = void 0;
      if (this.get('featureFlags').checkEnabled('MultipleOptionsGroup')) {
        startKey = 'startAndEndTime';
      } else {
        startKey = 'startTime';
      }
      if (isGroundTransfer) {
        if (isNew || this.controller.get('scheduleSupertype') === 'hours') {
          scheduleTypeLabels = [startKey, 'operatingHours'];
          scheduleTypeValues = ['Start', 'Hours of Operation'];
        } else {
          scheduleTypeLabels = [startKey];
          scheduleTypeValues = ['Start'];
        }
      } else {
        if (isNew || this.controller.get('scheduleSupertype') === 'hours') {
          scheduleTypeLabels = [startKey, 'operatingHours', 'multiDay', 'validDays', 'validHours'];
          scheduleTypeValues = ['Start', 'Hours of Operation', 'Multi-day', 'Valid Days', 'Valid Hours'];
        } else {
          scheduleTypeLabels = [startKey, 'multiDay'];
          scheduleTypeValues = ['Start', 'Multi-day'];
        }
      }

      var scheduleTypeOptions = this.setupDropdownOptionLabels('schedule', scheduleTypeLabels);
      this.controller.set('scheduleTypeOptions', scheduleTypeOptions);
      this.controller.set('scheduleTypeValues', scheduleTypeValues);
    },
    setScheduleSupertype: function setScheduleSupertype(type) {
      switch (type) {
        case 'Valid Days':
        case 'Valid Hours':
          this.controller.set('disableDuration', true);
          this.controller.set('scheduleSupertype', 'hours');
          break;
        case 'Hours of Operation':
          this.controller.set('disableDuration', false);
          this.controller.set('scheduleSupertype', 'hours');
          break;
        case 'Start':
        case 'Multi-day':
          this.controller.set('scheduleSupertype', 'start');
          break;
      }
    },
    setupDaysDropdown: function setupDaysDropdown() {
      var possibleMultiDays = (0, _arrayValues.getArrayOfValues)(22);
      this.controller.set('multiDaysOptions', possibleMultiDays);
      this.controller.set('multiDaysValues', possibleMultiDays);

      var possibleValidDays = (0, _arrayValues.getArrayOfValues)(30);
      possibleValidDays.push(60, 90);
      this.controller.set('validDaysOptions', possibleValidDays);
      this.controller.set('validDaysValues', possibleValidDays);

      var isNew = this.controller.get('offer.isNew');
      var scheduleType = this.controller.get('offer.offerAttributes.scheduleType');
      if (!isNew && (scheduleType === 'Multi-day' || scheduleType === 'Valid Days')) {
        var durationInDays = this.controller.get('offer.durationInMinutes') / 60 / 24;
        this.controller.set('selectedDays', durationInDays);
        this._daysChange(durationInDays);
        if (scheduleType === 'Valid Days') {
          this._setUsageDaysDropDown(durationInDays);
        }
      } else if (scheduleType === 'Start' || scheduleType === 'Valid Hours' || scheduleType === 'Hours of Operation') {
        this.controller.set('selectedDays', 0);
      } else {
        this.controller.set('selectedDays', 1);
      }
    },
    setupHoursDropdown: function setupHoursDropdown() {
      var possibleHours = (0, _arrayValues.getArrayOfValues)(100);

      this.controller.set('hoursOptions', possibleHours);
      this.controller.set('hoursValues', possibleHours);

      var isNew = this.controller.get('offer.isNew');
      var scheduleType = this.controller.get('offer.offerAttributes.scheduleType');
      if (!isNew && scheduleType === 'Valid Hours') {
        var durationInHours = Math.floor(this.controller.get('offer.durationInMinutes') / 60);
        this.controller.set('selectedHours', durationInHours);
      } else {
        switch (scheduleType) {
          case 'Valid Hours':
            this.controller.set('selectedHours', 1);
            break;
          default:
            this.controller.set('selectedHours', 0);
            break;
        }
      }
    },
    setupCapacityTypeDropdown: function setupCapacityTypeDropdown() {
      var capacityTypes = ['limited', 'unlimited'];
      var capacityTypeOptions = this.setupDropdownOptionLabels('capacity', capacityTypes);
      this.controller.set('capacityTypeOptions', capacityTypeOptions);
      this.controller.set('capacityTypeValues', ['true', 'false']);
    },
    setupCancellationTypeDropdown: function setupCancellationTypeDropdown() {
      var cancellationTypes = void 0;
      var cancellationEnum = void 0;
      var cancellationType = this.controller.get('offer.cancellationPolicyType');
      if (this.get('permissions').checkEnabled('V3InternalSettings') || cancellationType === 'Non Refundable') {
        cancellationTypes = ['refundable', 'nonrefundable'];
        cancellationEnum = ['Refundable', 'Non Refundable'];
      } else {
        cancellationTypes = ['refundable'];
        cancellationEnum = ['Refundable'];
      }
      var cancellationTypeOptions = this.setupDropdownOptionLabels('cancellationPolicy', cancellationTypes);
      this.controller.set('cancellationTypeOptions', cancellationTypeOptions);
      this.controller.set('cancellationTypeValues', cancellationEnum);
    },
    setupMealTypeDropdown: function setupMealTypeDropdown() {
      var mealTypes = ['foodOnly', 'drinksOnly', 'foodAndDrinks', 'notApplicable'];
      var mealEnum = ['Food Only', 'Drinks Only', 'Food and Drinks', 'Not applicable'];
      var mealTypeOptions = this.setupDropdownOptionLabels('meal', mealTypes);
      this.controller.set('mealTypeOptions', mealTypeOptions);
      this.controller.set('mealTypeValues', mealEnum);
    },
    setupLanguage: function setupLanguage(languages) {
      this.setupLanguageLookupHash(languages);
      var languageTypeValues = [1, 2, 3];
      var languageTypeOptions = this.setupDropdownOptionLabels('language', languageTypeValues);
      this.controller.set('languageTypeOptions', languageTypeOptions);
      this.controller.set('languageTypeValues', languageTypeValues);

      var selectedLanguageIDs = this.controller.get('offer.offerAttributes.languageInfo.languageIDs');
      this.controller.set('selectedLanguages', selectedLanguageIDs);
      this.controller.set('languageOptions', languages);
      var selectedLanguageStrings = this.getSelectedLanguageString(selectedLanguageIDs, languages);
      this.generateLanguageString(selectedLanguageStrings);
    },
    setupLanguageLookupHash: function setupLanguageLookupHash(languages) {
      var lookUpObject = {};
      for (var i = 0; i < languages.length; i++) {
        lookUpObject[languages[i].id] = languages[i].label;
      }
      this.controller.set('languageLookupHash', lookUpObject);
      this.controller.set('offer.languageLookupHash', lookUpObject);
    },
    setupPriceGroup: function setupPriceGroup() {
      var _this4 = this;

      if (this.controller.get('offer.prices.length') === 0) {
        this.controller.set('editingPg', true);
        var priceGroup = this.store.createRecord('priceGroup');
        var price = this.store.createRecord('ticket-detail');
        priceGroup.get('prices').pushObject(price);
        if (this.get('featureFlags').checkEnabled('PriceUpdate')) {
          priceGroup.set('priceUpdateAlertVisible', false);
        }
        var scheduleType = this.controller.get('offer.offerAttributes.scheduleType');
        if (scheduleType === 'Hours of Operation') {
          priceGroup.get('availabilities.firstObject.availability').forEach(function (day) {
            day.open = '00:00';
            day.close = '23:59';
          });
        }
        this.controller.get('offer.prices').pushObject(priceGroup);
      } else {
        this.controller.get('offer.prices').forEach(function (data) {
          if (_this4.get('featureFlags').checkEnabled('PriceUpdate')) {
            data.set('priceUpdateAlertVisible', false);
          }
          if (data.get('isNew')) {
            _this4.controller.set('editingPg', true);
            var _scheduleType = _this4.controller.get('offer.offerAttributes.scheduleType');
            if (_scheduleType === 'Hours of Operation') {
              data.get('availabilities.firstObject.availability').forEach(function (day) {
                day.open = '00:00';
                day.close = '23:59';
              });
            }
          }
        });
      }
    },
    setupSchedule: function setupSchedule() {
      if (this.controller.get('offer.schedule.length') === 0) {
        this.controller.get('offer.schedules').pushObject(this.store.createRecord('schedule', {
          id: 1
        }));
      }
    },
    setupDropdownOptionLabels: function setupDropdownOptionLabels(labelsFor, labels) {
      var _this5 = this;

      return labels.map(function (type) {
        return _this5.get('i18n').t('pages.offer.' + labelsFor + 'Types.' + type).string;
      });
    },
    validateFields: function validateFields() {
      var offer = this.controller.get('offer');
      var offerAttributes = this.controller.get('offer.offerAttributes');
      var languageInfo = this.controller.get('offer.offerAttributes.languageInfo');
      if (offer.get('isMultipleOptionGroup')) {
        var nonOfferGroupValidations = (0, _formValidator.validate)({ model: offer, attributes: ['shortTitle', 'isLimitedInventory', 'bookingCutoffInHours', 'cancellationPolicyType', 'cancellationCutoffInHours', 'offerDetails'] }, { model: offerAttributes, attributes: ['mealType'] });
        return this.validateOfferGroupFields(nonOfferGroupValidations);
      } else {
        return (0, _formValidator.validate)({ model: offer, attributes: ['shortTitle', 'isLimitedInventory', 'bookingCutoffInHours', 'durationInMinutes', 'cancellationPolicyType', 'cancellationCutoffInHours'] }, { model: offerAttributes, attributes: ['scheduleType', 'startTime', 'endTime', 'mealType'] }, { model: languageInfo, attributes: ['languageTypeId'] });
      }
    },
    validatePriceFields: function validatePriceFields(pg) {
      var priceGroupAttributes = ['travelStart', 'travelEnd', 'bookingStart', 'bookingEnd', 'someAvailability'];

      var ticketValidations = ['retailPrice', 'marketingPrice', 'ticketTypeId', 'validMinMaxRange', 'priceCodeSKU'];

      if (this.controller.get('offer.offerAttributes.isPriceByVolume')) {
        ticketValidations.push('minimumTravelers', 'maximumTravelers');
        priceGroupAttributes.push('noGroupOverlap');
      } else {
        priceGroupAttributes.push('duplicateTickets', 'noOverlapTicketTypes', 'uniqueTicketTypes');
      }

      var pgGroup = (0, _formValidator.validate)({ model: pg,
        attributes: priceGroupAttributes
      });

      var tickets = pg.get('prices').filter(function (p) {
        return p.get('operation') !== 'delete';
      }).map(function (ticket, idx) {
        return (0, _formValidator.validate)({
          model: ticket,
          attributes: ticketValidations
        });
      });

      var dayAvailabilityValidationArray = new Ember.A(['capacity']);
      if (this.controller.get('offer.offerAttributes.scheduleType') === 'Hours of Operation') {
        dayAvailabilityValidationArray.pushObjects(['open', 'close', 'timeRange']);
      }

      var dayAvailability = pg.get('availabilities.firstObject.availability').map(function (day, idx) {
        return (0, _formValidator.validate)({
          model: day,
          attributes: dayAvailabilityValidationArray
        });
      });

      function buildValidationArray(validationGroup) {
        return validationGroup.filterBy('state', 'rejected').map(function (promise) {
          return promise.reason;
        }).reduce(function (a, b) {
          return Object.assign(a, b);
        }, []);
      }

      function compileMessagesForSingleDisplay(validations) {
        var messages = new Ember.A([]);
        validations.forEach(function (error) {
          if (error.reason) {
            for (var messageKey in error.reason) {
              messages.pushObjects(error.reason[messageKey]);
            }
          }
        });
        return messages.uniq();
      }

      return new Ember.RSVP.Promise(function (resolve, reject) {
        Ember.RSVP.allSettled(dayAvailability).then(function (availabilityValidations) {
          return Ember.RSVP.allSettled(tickets).then(function (ticketValidations) {
            return Ember.RSVP.allSettled([pgGroup]).then(function (pgGroupValidations) {
              var priceGroupErrors = buildValidationArray(pgGroupValidations);

              var availabilityMessages = compileMessagesForSingleDisplay(availabilityValidations);
              priceGroupErrors.availability = availabilityMessages || {};

              var allValidationErrors = {
                priceGroupErrors: priceGroupErrors,
                ticketErrors: ticketValidations,
                availabilityErrors: availabilityValidations
              };
              //use directly from promise settling as some filtering and building
              //can take a event loop
              if ((0, _offerGroup.anyRejects)(pgGroupValidations) || (0, _offerGroup.anyRejects)(ticketValidations) || (0, _offerGroup.anyRejects)(availabilityValidations)) {
                reject(allValidationErrors);
              } else {
                resolve();
              }
            });
          });
        });
      });
    },
    disableFields: function disableFields() {
      var isNew = this.controller.get('offer.isNew');

      if (!isNew) {
        this.controller.set('offer.viewOnlyAfterSave', true);
        this.controller.set('offer.viewOnlyUntilEdit', true);
        this.controller.set('offer.viewOnlyUnlessSuperUser', true);
        this.controller.set('offer.cancellationDisabled', true);
        this.controller.set('offer.viewOnlyIfActivePricing', true);
        this.controller.set('offer.viewOnlyUntilEditMF', true);
        this.controller.set('offer.viewOnlyUntilEditOF', true);
      }
    },
    setFieldsForGroundTransfer: function setFieldsForGroundTransfer() {
      if (this.controller.get('activity.isViewOnlyForGroundTransfer')) {
        this.send('mealTypeChange', 'Not applicable');
        this.send('languageTypeIdChange', 3);
        var isNew = this.controller.get('offer.isNew');
        if (isNew) {
          this.controller.set('offer.isLimitedInventory', false);
        }
      }
    },
    copyLanguageDisplayString: function copyLanguageDisplayString() {
      if (this.controller.get('selectedLanguageDisplayCopy') === undefined) {
        this.controller.set('selectedLanguageDisplayCopy', this.controller.get('selectedLanguageDisplay'));
      }
    },
    generateLanguageString: function generateLanguageString(languageStringArray) {
      var languageString = '';
      if (languageStringArray.length > 1) {
        languageString = 'in Multilingual';
      } else if (languageStringArray.length === 1) {
        languageString = 'in ' + languageStringArray[0];
      }

      this.controller.set('selectedLanguageDisplay', languageStringArray.join(', '));
      this.controller.set('languageString', languageString);
      this.generateOfferString();
    },
    generateLanguageStringArray: function generateLanguageStringArray(languageIDArray) {
      if (!languageIDArray) {
        return [];
      }
      var languageStringArray = [];
      var languages = this.controller.get('languageLookupHash');

      for (var i = 0; i < languageIDArray.length; i++) {
        languageStringArray.push(languages[languageIDArray[i]]);
      }

      return languageStringArray;
    },
    generateOfferString: function generateOfferString() {
      var offerPostfixString = '';
      var controller = this.controller;

      var mealString = controller.get('mealString') ? controller.get('mealString') : '';
      mealString = mealString !== 'Not applicable' ? mealString : '';
      var languageString = controller.get('languageString') ? controller.get('languageString') : '';

      if (mealString.length > 0 && languageString.length > 0) {
        offerPostfixString = languageString + ', includes ' + mealString;
      } else if (mealString.length > 0) {
        offerPostfixString = 'includes ' + mealString;
      } else if (languageString.length > 0) {
        offerPostfixString = languageString;
      }
      this.controller.set('offerPostfix', offerPostfixString);
    },
    generateDescriptionString: function generateDescriptionString(languageTypeID, languageStringArray) {
      var newDescription = '';
      if (languageStringArray[0] !== undefined) {
        if (languageTypeID === 1) {
          return newDescription = 'Live Commentary in ' + languageStringArray[0] + '.';
        } else if (languageTypeID === 2) {
          if (languageStringArray.length > 1) {
            var lastIndex = languageStringArray.length - 1;
            var lastItem = languageStringArray[lastIndex];
            lastItem = 'and ' + lastItem;
            languageStringArray[lastIndex] = lastItem;
          }
          var languageString = languageStringArray.join(', ');

          if (languageString.length === 0) {
            return newDescription;
          } else {
            return newDescription = 'Audio guide in ' + languageString + '.';
          }
        }
      }
    },
    singleLanguageChange: function singleLanguageChange(languageIDArray, languageString) {
      var newLanguageArray = new Ember.A();
      newLanguageArray.push(languageIDArray[0]);

      this.controller.set('offer.offerAttributes.languageInfo.languageIDs', newLanguageArray);
      this.controller.set('selectedLanguages', newLanguageArray);
      this.controller.set('selectedLanguageDisplay', languageString);
      this.generateLanguageString([languageString]);
      this.generateDescription();
    },
    generateDescription: function generateDescription() {
      var originalDescription = this.controller.get('offer.description');
      var languageTypeID = parseInt(this.controller.get('offer.offerAttributes.languageInfo.languageTypeId'));
      var languageIDs = this.controller.get('offer.offerAttributes.languageInfo.languageIDs');
      var languageStringArray = this.generateLanguageStringArray(languageIDs);
      var newDescription = '';

      // This block prevents auto gen description setting to something like
      // 'Live Commentary in undefined.'
      if (languageStringArray.length === 0) {
        var previouslySelected = this.controller.get('selectedLanguages');
        if (previouslySelected.length !== 0) {
          if (languageTypeID === 1) {
            languageStringArray = this.generateLanguageStringArray([previouslySelected[0]]);
          } else if (languageTypeID === 2) {
            languageStringArray = this.generateLanguageStringArray(previouslySelected);
          }
        }
      }

      // Generates the description:
      if (CKEDITOR.instances['lx-ckeditor textarea-offer-description']) {
        newDescription = this.generateDescriptionString(languageTypeID, languageStringArray);

        if (originalDescription === undefined || originalDescription.length === 0) {
          // Sets description for the first time
          CKEDITOR.instances['lx-ckeditor textarea-offer-description'].setData(newDescription);
          this.controller.set('offer.description', newDescription);
          this.controller.set('generatedDescriptionString', newDescription);
        } else if (newDescription !== undefined) {
          // Sets description all subsequent times
          this.descriptionFindAndReplace(originalDescription, newDescription);
        } else if (newDescription === undefined && languageTypeID === 3) {
          this.descriptionFindAndReplace(originalDescription, newDescription);
        }
      }
    },
    generateLongTitle: function generateLongTitle() {
      this.generateOfferString();

      var type = this.controller.get('offer.offerAttributes.scheduleType');
      var prefix = this.controller.get('titlePrefix');
      var title = this.controller.get('offer.shortTitle');
      var postfix = this.controller.get('offerPostfix');

      var longTitle = void 0;
      if (type === 'Hours of Operation') {
        longTitle = title + ' ' + postfix;
      } else {
        longTitle = prefix + ', ' + title + ' ' + postfix;
      }

      this.controller.set('offer.title', longTitle);
    },
    descriptionFindAndReplace: function descriptionFindAndReplace(original, replacement) {
      var replacementDesc = replacement;
      if (replacement === undefined) {
        replacementDesc = '';
      }

      var generatedDescriptionString = this.controller.get('generatedDescriptionString');
      var exactStringRegex = new RegExp(generatedDescriptionString, 'g');
      var originalIndex = original.search(exactStringRegex);
      var previouslyGeneratedLength = generatedDescriptionString.length;

      // If switching to Not Included:
      if (originalIndex === -1) {
        originalIndex = 0;
      }

      // Pulls apart description pieces, puts new description together
      var firstHalf = original.substring(0, originalIndex);
      var lastHalf = original.substring(originalIndex + previouslyGeneratedLength);
      var updatedDescription = firstHalf + ' ' + replacementDesc + ' ' + lastHalf;

      // Actually sets the values
      this.controller.set('offer.description', updatedDescription);
      this.controller.set('generatedDescriptionString', replacementDesc);
      CKEDITOR.instances['lx-ckeditor textarea-offer-description'].setData(updatedDescription);
    },
    rollbackOfferTitle: function rollbackOfferTitle(languageTypeId, languages, mealType, offer) {
      var originalLanguageType = offer.get('offerAttributes.languageInfo.languageTypeId');
      var originalLanguages = offer.get('offerAttributes.languageInfo.languageIDs');
      var originalMealType = offer.get('offerAttributes.mealType');

      if (originalLanguageType !== languageTypeId) {
        this.generateOfferString();
      }

      if (originalLanguages !== languages) {
        this.controller.set('selectedLanguages', originalLanguages);
        var originalLanguageString = this.controller.get('selectedLanguageDisplayCopy');

        this.generateLanguageStringArray(originalLanguages);
        if (typeof originalLanguageString === 'string') {
          this.generateLanguageString([originalLanguageString]);
        } else {
          this.generateLanguageString(originalLanguageString);
        }
      }

      if (originalMealType !== mealType) {
        this.controller.set('mealString', originalMealType);
        this.generateOfferString();
      }
      if (!offer.get('isMultipleOptionGroup')) {
        this.generateDescription();
      }
    },
    createClonePg: function createClonePg(pg) {
      var _this6 = this;

      var generatePriceArray = function generatePriceArray(clonePrices, prices) {
        prices.forEach(function (price) {
          if (!price.get('isNew')) {
            clonePrices.pushObject(_this6.store.createRecord('ticket-detail', {
              ticketTypeId: price.get('ticketTypeId').toString(),
              retailPrice: price.get('retailPrice'),
              marketingPrice: price.get('marketingPrice'),
              priceCodeSKU: price.get('priceCodeSKU'),
              minimumTravelers: price.get('minimumTravelers'),
              maximumTravelers: price.get('maximumTravelers'),
              groupPricePlanType: price.get('groupPricePlanType'),
              partnerTicketTypeId: price.get('partnerTicketTypeId'),
              margin: price.get('margin')
            }));
          }
        });
      };

      var generateAvailabilityArray = function generateAvailabilityArray(cloneAvailability, availability) {
        cloneAvailability.forEach(function (day, idx) {
          day.setProperties({
            day: availability.objectAt(idx).get('day'),
            open: availability.objectAt(idx).get('open'),
            close: availability.objectAt(idx).get('close'),
            available: availability.objectAt(idx).get('available'),
            capacity: availability.objectAt(idx).get('capacity')
          });
        });
      };

      var clone = this.store.createRecord('price-group', {
        travelStart: pg.get('travelStart'),
        travelEnd: pg.get('travelEnd'),
        bookingStart: pg.get('bookingStart'),
        bookingEnd: pg.get('bookingEnd'),
        bookingDatesDifferent: pg.get('bookingDatesDifferent')
      });

      generatePriceArray(clone.get('prices'), pg.get('prices'));
      generateAvailabilityArray(clone.get('availabilities.firstObject.availability'), pg.get('availabilities.firstObject.availability'));

      return clone;
    },
    verifyPublishAttributes: function verifyPublishAttributes() {
      var changedAttrs = Object.keys(this.controller.get('offer').changedAttributes());
      var attrsToPublish = ['description', 'shortTitle', 'title'];
      var hasAttrsToPublishChanged = _array2.default.intersection(changedAttrs, attrsToPublish).length > 0;
      var hasNewOffers = this.controller.get('hasNewOffers');
      var needsPublish = hasAttrsToPublishChanged || hasNewOffers;
      this.controller.set('hasAttrsToPublishChanged', needsPublish);
    },
    removeDispersedTickets: function removeDispersedTickets(priceGroup) {
      priceGroup.get('prices').forEach(function (priceInfo, index) {
        if (!priceInfo.get('ticketTypeId')) {
          priceGroup.get('prices').removeAt(index);
        }
      });
    },
    createCsvAndDownload: function createCsvAndDownload(data, fileName) {
      var blob = new Blob([data], { type: 'text/csv' });
      this.get('fileSaver').saveAs(blob, fileName);
    },


    actions: {
      saveMandatoryFees: function saveMandatoryFees(feeArrays) {
        var _this7 = this;

        this.controller.set('savingMf', true);
        console.log('feeArrays inside route save Mandatory fees', feeArrays);

        // Format the data to match the API request structure
        var additionalFees = feeArrays.map(function (fee) {
          return {
            additionalFeesID: fee.additionalFeeId,
            startDate: fee.startDate,
            endDate: fee.endDate,
            supplierCurrencyCode: fee.supplierCurrencyCode,
            offerId: fee.offerId,
            additionalFeeTypesID: fee.additionalFeeTypesID,
            ticketTypeID: fee.ticketTypeId,
            activityID: fee.activityId,
            mandatory: 1,
            amount: fee.amount,
            feeDesc: '',
            iStatus: fee.status,
            typeDesc: '',
            ticketTypeName: fee.ticketType
          };
        });

        console.log('additionalFees inside route save Mandatory fees', additionalFees);

        // Create the payload for the PUT request
        var data = {
          additionalFees: additionalFees
        };

        console.log('Formatted data for API:', data);

        // Define the URL for the PUT request
        var url = _environment.default.api.host + '/api/v3/additionalFee';

        // Send the PUT request using ajaxWrapper
        this.get('ajaxWrapper').ajaxPut(url, data).then(function (response) {
          // Success handler
          console.log('API call success:', response);
          // Additional success logic if needed
          _this7.controller.send('showAlert', 'Mandatory fees saved successfully.');
        }).catch(function (error) {
          // Error handler
          console.error('API call failed:', error);
          // Call showAlert with failure message
          _this7.controller.send('showAlert', 'Failed to save mandatory fees. Please try again.');
        }).finally(function () {
          _this7.controller.set('savingMf', false);
          _this7.controller.set('offer.mandatoryFeeEditingSetup', false);
          _this7.controller.set('offer.viewOnlyUntilEditMF', true);
          console.log('inside finally block....');
        });
      },
      saveOptionalFees: function saveOptionalFees(feeArrays) {
        var _this8 = this;

        console.log('feeArrays inside route save Optional fees', feeArrays);
        this.controller.set('savingOf', true);

        // Format the data to match the API request structure
        var additionalFees = feeArrays.map(function (fee) {
          return {
            additionalFeesID: fee.additionalFeeId,
            startDate: fee.startDate,
            endDate: fee.endDate,
            offerId: fee.offerId,
            additionalFeeTypesID: fee.additionalFeeTypesID,
            ticketTypeID: fee.ticketTypeId,
            activityID: fee.activityId,
            mandatory: 0,
            amount: fee.amount,
            feeDesc: fee.description,
            iStatus: fee.status,
            typeDesc: fee.description,
            ticketTypeName: fee.ticketType
          };
        });

        console.log('additionalFees inside route save Optional fees', additionalFees);

        // Create the payload for the PUT request
        var data = {
          additionalFees: additionalFees
        };

        console.log('Formatted data for API:', data);

        // Define the URL for the PUT request
        var url = _environment.default.api.host + '/api/v3/additionalFee';

        // Send the PUT request using ajaxWrapper
        this.get('ajaxWrapper').ajaxPut(url, data).then(function (response) {
          // Success handler
          console.log('API call success:', response);
          // Additional success logic if needed
          _this8.controller.send('showAlert', 'Optional fees saved successfully.');
        }).catch(function (error) {
          // Error handler
          console.error('API call failed:', error);
          // Display the error in the console or show a user-facing error message
          _this8.controller.send('showAlert', 'Failed to save optional fees. Please try again.');
        }).finally(function () {
          _this8.controller.set('savingOf', false);
          _this8.controller.set('offer.optionalFeeEditingSetup', false);
          _this8.controller.set('offer.viewOnlyUntilEditOF', true);
          console.log('save optional fees inside finally block....');
        });
      },
      scheduleTypeChange: function scheduleTypeChange(type) {
        this.controller.set('offer.offerAttributes.scheduleType', type);
        this.setScheduleSupertype(type);

        var supertype = this.controller.get('scheduleSupertype');
        // Default to limited inventory for 'Start Time' and default to
        // free sell for 'Hours of Operation'
        if (this.controller.get('offer.isNew')) {
          if (supertype === 'start') {
            this.send('capacityTypeChange', 'true');
          } else if (supertype === 'hours') {
            this.send('capacityTypeChange', 'false');
          }

          this.controller.get('offer').set('isMultipleOptionGroup', (this.get('featureFlags').checkEnabled('MultipleOptionsGroupAllSchedules') || type === 'Start') && this.get('featureFlags').checkEnabled('MultipleOptionsGroup'));
        }

        if (this.get('featureFlags').checkEnabled('MultipleOptionsGroup')) {
          var isMultipleOptionGroup = this.controller.get('offer.isMultipleOptionGroup') === true;
          var langTypeId = this.controller.get('offer.offerAttributes.languageInfo.languageTypeId');
          if (isMultipleOptionGroup && type === 'Start') {
            this.controller.set('offer.description', '');
            CKEDITOR.instances['lx-ckeditor textarea-offer-description'].setData('');
          } else if (parseInt(langTypeId) > 0) {
            this.send('languageTypeIdChange', langTypeId);
          }
        }

        if (type === 'Multi-day' || type === 'Valid Days') {
          this.send('hoursChange', 0);
          if (type === 'Multi-day') {
            this.send('multiDaysChange', 1);
          } else {
            this.send('validDaysChange', 1);
          }
        } else {
          this.send('validDaysChange', 0);

          if (type === 'Valid Hours') {
            this.send('hoursChange', 1);
          } else {
            this.send('hoursChange', 0);
          }
        }

        this.send('updateTitlePrefix');
      },
      usageDaysChange: function usageDaysChange(usageDays) {
        this._usageDaysChange(usageDays);
      },
      validDaysChange: function validDaysChange(days) {
        this._daysChange(days);
        this._usageDaysChange(days);
        this._setUsageDaysDropDown(days);
      },
      multiDaysChange: function multiDaysChange(days) {
        this._daysChange(days);
      },
      hoursChange: function hoursChange(hours) {
        var _this9 = this;

        var hoursInt = parseInt(hours);
        this.controller.set('selectedHours', hoursInt);
        this.controller.set('offer.durationInMinutes', hoursInt * 60);
        Ember.run.next(function () {
          _this9.send('updateTitlePrefix');
        });
      },
      updateTitlePrefix: function updateTitlePrefix() {
        this._updateTitlePrefix();
      },
      capacityTypeChange: function capacityTypeChange(limitedCapacity) {
        var booleanCapacity = limitedCapacity !== 'false';
        this.controller.set('offer.isLimitedInventory', booleanCapacity);
      },
      cancellationTypeChange: function cancellationTypeChange(cancellationType) {
        this.controller.set('offer.cancellationPolicyType', cancellationType);
      },
      mealTypeChange: function mealTypeChange(mealType) {
        var mealString = mealType === 'Not applicable' ? '' : mealType;
        this.controller.set('mealString', mealString);
        this.controller.set('offer.offerAttributes.mealType', mealType);
        this.generateOfferString();
      },
      languageTypeIdChange: function languageTypeIdChange(type) {
        var selectedLanguageIds = [];
        var intType = parseInt(type);
        var controller = this.controller;
        controller.set('offer.offerAttributes.languageInfo.languageTypeId', intType);

        if (intType === 1) {
          var languageIDsArray = controller.get('offer.offerAttributes.languageInfo.languageIDs');
          if (languageIDsArray.length === 0) {
            languageIDsArray = controller.get('selectedLanguages');
          }
          selectedLanguageIds = languageIDsArray;
          if (languageIDsArray.length > 1) {
            var autoSelectLanguageID = [languageIDsArray[0]];
            var autoSelectLanguageString = controller.get('languageLookupHash')[autoSelectLanguageID[0]];
            selectedLanguageIds = autoSelectLanguageID;
            this.singleLanguageChange(autoSelectLanguageID, autoSelectLanguageString);
          }
          controller.set('offer.offerAttributes.languageInfo.languageIDs', selectedLanguageIds);
          controller.set('showLanguageDropdown', 'singleLanguage');
        } else if (intType === 2) {
          selectedLanguageIds = this.controller.get('selectedLanguages');
          controller.set('offer.offerAttributes.languageInfo.languageIDs', selectedLanguageIds);
          controller.set('showLanguageDropdown', 'multiLanguage');
        } else if (intType === 3) {
          controller.set('offer.offerAttributes.languageInfo.languageIDs', []);
          controller.set('showLanguageDropdown', '');
          controller.set('languageString', '');
        }

        var languageStringArray = this.generateLanguageStringArray(selectedLanguageIds);
        this.generateLanguageString(languageStringArray);
        this.generateDescription();
      },
      languageChange: function languageChange(languageId, languageString) {
        var languageIDsArray = [parseInt(languageId)];
        this.singleLanguageChange(languageIDsArray, languageString);
      },
      selectMultipleLanguages: function selectMultipleLanguages(languageID) {
        var languageIDsArray = this.controller.get('offer.offerAttributes.languageInfo.languageIDs');
        languageIDsArray = languageIDsArray ? languageIDsArray : Ember.A([]);
        var newLanguageArray = new Ember.A(languageIDsArray.slice());

        if (newLanguageArray.includes(languageID)) {
          var index = newLanguageArray.indexOf(languageID);
          newLanguageArray.splice(index, 1);
        } else {
          newLanguageArray.pushObject(languageID);
        }
        var languageStringArray = this.generateLanguageStringArray(newLanguageArray);
        this.controller.set('offer.offerAttributes.languageInfo.languageIDs', newLanguageArray);
        this.controller.set('selectedLanguages', newLanguageArray);
        this.controller.set('selectedLanguageDisplay', languageStringArray.join(', '));
        this.generateLanguageString(languageStringArray);
        this.generateDescription();
      },
      toggleUsageDays: function toggleUsageDays() {
        this.controller.set('offer.offerAttributes.usageDays', null);
        this.controller.set('usageDaysCheckboxClicked', true);
        this.setDirtyAttribute();
      },
      toggleVolumePricing: function toggleVolumePricing() {
        (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.VBPCheckbox');
        var byVolume = this.controller.get('offer.offerAttributes.isPriceByVolume');
        this.controller.set('offer.offerAttributes.isPriceByVolume', !byVolume);
        this.setDirtyAttribute();
      },
      togglePricingIngestion: function togglePricingIngestion() {
        var byPIngestion = this.controller.get('offer.offerAttributes.isPricingIngestion');
        this.controller.set('offer.offerAttributes.isPricingIngestion', !byPIngestion);
        this.setDirtyAttribute();
      },
      toggleHotelPickup: function toggleHotelPickup() {
        this.controller.toggleProperty('offer.offerAttributes.isHotelPickupEnabled');
        this.setDirtyAttribute();
      },
      addPriceGroup: function addPriceGroup() {
        (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.AddPriceGroup');
        this.controller.set('editingPg', true);
        var priceGroup = this.store.createRecord('priceGroup');
        var price = this.store.createRecord('ticket-detail');
        priceGroup.get('prices').pushObject(price);
        var scheduleType = this.controller.get('offer.offerAttributes.scheduleType');
        if (scheduleType === 'Hours of Operation') {
          priceGroup.get('availabilities.firstObject.availability').forEach(function (day) {
            day.open = '00:00';
            day.close = '23:59';
          });
        }

        this.controller.get('offer.prices').pushObject(priceGroup);
        this.controller.goToPage(this.controller.get('totalPages'));
      },
      addPrices: function addPrices(pg, volumeTicketTypeId, volumeGroupType, volumeGroupTicketType) {
        var price = this.store.createRecord('ticket-detail', {
          ticketTypeId: volumeTicketTypeId,
          groupPricePlanType: volumeGroupType,
          groupTicketTypeId: volumeGroupTicketType
        });
        //set operation to 'create' when new ticket is added to existing Price Group
        if (this.get('featureFlags').checkEnabled('PriceUpdate')) {
          if (pg.get('id') !== null) {
            price.set('operation', 'create');
          }
          //add margin value to newly created ticket in VBP price
          if (typeof volumeTicketTypeId !== 'undefined') {
            price.set('margin', (0, _toPercentWithoutPercentSign.default)(this.get('netPrice').getMargin(volumeTicketTypeId)));
          }
        }
        pg.get('prices').pushObject(price);
      },
      clonePg: function clonePg(pg) {
        var clone = this.createClonePg(pg);
        this.setDirtyAttribute();
        //refactor with add price gorup
        this.controller.set('editingPg', true);
        this.controller.get('offer.prices').pushObject(clone);

        Ember.run.later(function () {
          var anchorArray = Ember.$('.price-group-anchor:last');
          Ember.$('html, body').animate({
            scrollTop: anchorArray.offset().top
          }, 250);
        });
        this.controller.goToPage(this.controller.get('totalPages'));
      },
      openDeactivatePg: function openDeactivatePg(pg, priceIds) {
        this.controller.set('deactivatePriceGroupOpen', true);
        this.controller.set('currentPg', pg);
        this.controller.set('currentPriceIds', priceIds);
      },
      deactivatePg: function deactivatePg() {
        var _this10 = this;

        var deactivatePriceGroupOpen = this.controller.get('deactivatePriceGroupOpen');

        if (deactivatePriceGroupOpen) {
          this.controller.set('isProcessingConfirmAction', true);
          var isMultipleOptionGroup = this.controller.get('offer.isMultipleOptionGroup');
          var offerId = this.controller.get('offer.id');
          var pg = this.controller.get('currentPg');
          var priceIds = this.controller.get('currentPriceIds');
          var url = void 0;
          if (this.get('featureFlags').checkEnabled('MultipleOptionsGroup') && isMultipleOptionGroup) {
            var priceGroupId = this.controller.get('currentPg.priceGroupId');
            url = _environment.default.api.host + '/api/v3/offerGroup/' + offerId + '/price/' + priceGroupId;
          } else {
            url = _environment.default.api.host + '/api/v3/offer/' + offerId + '/price';
          }

          this.get('ajaxWrapper').ajaxDelete(url, { priceIds: priceIds }, undefined, { dataType: 'text' }).then(function () {
            var offerPrices = _this10.controller.get('offer.prices');
            offerPrices.removeObject(pg);
            _this10.controller.set('currentPg', null);
            _this10.controller.set('currentPriceIds', null);
            _this10.controller.set('deactivatePriceGroupOpen', false);
            if (offerPrices.get('length') === 0) {
              _this10.send('addPriceGroup');
            }
          }).catch(function (err) {
            Ember.Logger.error('error deleting', err);
            if (err && err.responseText && err.status) {
              var errorMessage = JSON.parse(err.responseText).errorMessage;
              var payloadErrors = {
                details: [{
                  message: errorMessage
                }]
              };
              _this10.controller.set('serverErrors', payloadErrors);
              _this10.controller.set('serverErrorStatus', err.status);
            }
          }).finally(function () {
            _this10.controller.set('isProcessingConfirmAction', false);
            if (_this10.controller.get('currentPage') > _this10.controller.get('totalPages')) {
              _this10.controller.goToPage(1);
            }
          });
        }
      },
      closeDeactivatePg: function closeDeactivatePg() {
        this.controller.set('deactivatePriceGroupOpen', false);
      },
      continue: function _continue() {
        (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.ContinueButton');
        this.transitionTo('activity.offer-list');
      },
      openStep: function openStep(headerClass) {
        var link = document.getElementsByClassName('title-main toggle-trigger ' + headerClass);

        if (headerClass === 'setup-offer') {
          if (this.controller.get('offer.setupOpen')) {
            this.controller.set('offer.setupOpen', false);
          } else {
            this.controller.set('offer.setupOpen', true);
          }
        } else if (headerClass === 'setup-prices') {
          if (this.controller.get('offer.pricesOpen')) {
            this.controller.set('offer.pricesOpen', false);
          } else {
            this.controller.set('offer.pricesOpen', true);
          }
        } else if (headerClass === 'setup-mandatory-fees') {
          if (this.controller.get('offer.mandatoryFeesOpen')) {
            this.controller.set('offer.mandatoryFeesOpen', false);
          } else {
            this.controller.set('offer.mandatoryFeesOpen', true);
          }
        } else if (headerClass === 'setup-optional-fees') {
          if (this.controller.get('offer.optionalFeesOpen')) {
            this.controller.set('offer.optionalFeesOpen', false);
          } else {
            this.controller.set('offer.optionalFeesOpen', true);
          }
        }
        $(link).toggleClass('open');
      },
      editSetup: function editSetup() {
        (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.OptionDetailEdit');
        //Check if volume pricing is enabled, and if there is active pricing
        var activePricing = this.controller.get('offer.prices').objectAt(0).get('id');

        // Run this again on edit, to ensure it updates to correct options
        this.setupScheduleTypeDropdown();

        var isSuperUser = this.get('EditV3OfferAttributesEnabled');
        var cancellationPolicyType = this.controller.get('offer.cancellationPolicyType');
        this.copyLanguageDisplayString();

        if (isSuperUser) {
          this.controller.set('offer.viewOnlyUnlessSuperUser', false);
        }
        if (!activePricing && isSuperUser) {
          this.controller.set('offer.viewOnlyIfActivePricing', false);
        }
        if (cancellationPolicyType === 'Non Refundable') {
          this.controller.set('offer.cancellationDisabled', false);
        }
        this.controller.set('offer.viewOnlyUntilEdit', false);
        this.controller.set('offer.editingSetup', true);

        this.controller.set('originalScheduleType', this.controller.get('offer.offerAttributes.scheduleType'));
        this.send('updateTitlePrefix');
        CKEDITOR.instances['lx-ckeditor textarea-offer-description'].setReadOnly(false);

        this.setOfferGroupCopy();
      },
      editSetupMandatoryFee: function editSetupMandatoryFee() {
        this.controller.set('offer.viewOnlyUntilEditMF', false);
        this.controller.set('offer.mandatoryFeeEditingSetup', true);
      },
      editSetupOptionalFee: function editSetupOptionalFee() {
        this.controller.set('offer.viewOnlyUntilEditOF', false);
        this.controller.set('offer.optionalFeeEditingSetup', true);
      },
      triggerUploadExcelDialog: function triggerUploadExcelDialog() {
        this.controller.set('showUploadDialog', true); // Open the upload dialog
      },
      downloadSampleCSV: function downloadSampleCSV() {
        var sampleData = 'OfferId,FeeTypeId,StartDate,EndDate,Amount,BookingType,SupplierCurrencyCode\n';
        var fileName = 'additional-fees-sample.csv';
        this.createCsvAndDownload(sampleData, fileName);
      },
      cancelEditSetup: function cancelEditSetup() {
        (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.OptionDetailCancelButton');
        this.controller.set('dirty', false);
        this.controller.set('errors', false);
        this.get('responseErrors').clearErrors();
        var languageTypeId = this.controller.get('offer.offerAttributes.languageInfo.languageTypeId');
        var languages = this.controller.get('offer.offerAttributes.languageInfo.languageIDs');
        var mealType = this.controller.get('offer.offerAttributes.mealType');
        var offer = this.controller.get('offer');

        this.controller.get('offer').rollbackAttributes();

        // Offer description value was updated by rollbackAttributes, but editor data must be set
        CKEDITOR.instances['lx-ckeditor textarea-offer-description'].setData(offer.get('description'));

        if (offer.get('offerAttributes.languageInfo.languageTypeId') === 3) {
          this.controller.set('generatedDescriptionString', '');
        }

        this.rollbackOfferTitle(languageTypeId, languages, mealType, offer);

        this.controller.set('offer.editingSetup', false);

        CKEDITOR.instances['lx-ckeditor textarea-offer-description'].setReadOnly(true);
        this.disableFields();
        this.rollBackOfferGroup();
      },
      cancelEditSetupMandatoryFee: function cancelEditSetupMandatoryFee() {
        this.controller.set('offer.mandatoryFeeEditingSetup', false);
        this.controller.set('offer.viewOnlyUntilEditMF', true);
      },
      cancelEditSetupOptionalFee: function cancelEditSetupOptionalFee() {
        this.controller.set('offer.optionalFeeEditingSetup', false);
        this.controller.set('offer.viewOnlyUntilEditOF', true);
      },
      updateTravelToDate: function updateTravelToDate(priceGroupIndex, date) {
        this.controller.get('offer.prices').objectAt(priceGroupIndex).set('travelEnd', date);
      },
      updateTravelFromDate: function updateTravelFromDate(priceGroupIndex, date) {
        this.controller.get('offer.prices').objectAt(priceGroupIndex).set('travelStart', date);
      },
      updateBookingToDate: function updateBookingToDate(priceGroupIndex, date) {
        this.controller.get('offer.prices').objectAt(priceGroupIndex).set('bookingEnd', date);
      },
      updateBookingFromDate: function updateBookingFromDate(priceGroupIndex, date) {
        this.controller.get('offer.prices').objectAt(priceGroupIndex).set('bookingStart', date);
      },
      checkForHigherCutOff: function checkForHigherCutOff(cancellationHours) {
        var _this11 = this;

        this.setDirtyAttribute();
        var isInternal = this.get('permissions').checkEnabled('V3InternalSettings');
        var param = this.controller.get('param');
        var prevCancellationType = this.controller.get('prevCancellationType');
        if (isInternal && param.offerId !== 'new' && param.clone !== 'true') {
          var prevCancellationCutOffHours = this.controller.get('prevCancellationCutOffHours');
          if (parseInt(cancellationHours.target.value) > prevCancellationCutOffHours && prevCancellationType !== 'Non Refundable') {
            this.controller.set('displayCancellationWorse', true);
          } else {
            this.controller.set('displayCancellationWorse', false);
          }
        }
        if (this.controller.get('errors') != false && !Ember.isEmpty(this.controller.get('errors'))) {
          this.controller.set('errors', undefined);
          this.validateFields().catch(function (error) {
            _this11.controller.set('errors', error);
          });
        }
      },
      checkCancellationType: function checkCancellationType(cancellationType) {
        var _this12 = this;

        this.setDirtyAttribute();
        var isInternal = this.get('permissions').checkEnabled('V3InternalSettings');
        var param = this.controller.get('param');
        var prevCancellationType = this.controller.get('prevCancellationType');
        if (isInternal && param.offerId !== 'new' && param.clone !== 'true' && prevCancellationType == 'Refundable' && cancellationType.target.value == 'Non Refundable') {
          this.controller.set('displayCancellationWorseType', true);
        } else {
          this.controller.set('displayCancellationWorseType', false);
        }
        if (cancellationType.target.value == 'Non Refundable') {
          this.controller.set('offer.cancellationCutoffInHours', 0);
        }
        if (this.controller.get('errors') != false && !Ember.isEmpty(this.controller.get('errors'))) {
          this.controller.set('errors', undefined);
          this.validateFields().catch(function (error) {
            _this12.controller.set('errors', error);
          });
        }
      },
      validateIfInvalid: function validateIfInvalid() {
        var _this13 = this;

        this.setDirtyAttribute();
        if (this.controller.get('errors') != false && !Ember.isEmpty(this.controller.get('errors'))) {
          this.controller.set('errors', undefined);
          this.validateFields().catch(function (error) {
            _this13.controller.set('errors', error);
          });
        }
      },
      validatePricingIfInvalid: function validatePricingIfInvalid(pg, setDirty) {
        var _this14 = this;

        if (setDirty) {
          this.setDirtyAttribute();
        }

        if (this.controller.get('pricingErrors')) {
          this.validatePriceFields(pg).then(function () {
            return _this14.controller.set('pricingErrors', null);
          }).catch(function (errors) {
            return _this14.controller.set('pricingErrors', errors);
          });
        }
      },
      showScheduleChangeConfirmationModal: function showScheduleChangeConfirmationModal() {
        var isNew = this.controller.get('offer.isNew');
        var originalScheduleType = this.controller.get('originalScheduleType');
        var currentScheduleType = this.controller.get('offer.offerAttributes.scheduleType');
        this.controller.set('usageDaysCheckboxClicked', false);

        (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.OptionDetailSaveButton');
        if (!isNew && originalScheduleType !== currentScheduleType) {
          this.controller.set('showScheduleChangeConfirmationModal', true);
        } else {
          this.controller.set('showScheduleChangeConfirmationModal', false);
          this.send('saveOffer');
        }
      },
      hideScheduleChangeConfirmationModal: function hideScheduleChangeConfirmationModal() {
        this.controller.set('showScheduleChangeConfirmationModal', false);
      },
      saveOffer: function saveOffer() {
        var _this15 = this;

        this.controller.set('errors', undefined);
        this.controller.set('lastUpdated', 'saveOffer');
        this.controller.set('showScheduleChangeConfirmationModal', false);
        this.controller.set('displayCancellationWorse', false);
        this.controller.set('displayCancellationWorseType', false);
        var isNew = this.controller.get('offer.isNew');
        this.setMultiOfferModel(this.controller.get('offer'));
        var activityId = this.controller.get('activity.id');
        this.validateFields().then(function () {
          _this15.controller.set('saving', true);
          _this15.generateLongTitle();
          _this15.verifyPublishAttributes();
          if (isNew) {
            return _this15.controller.get('offer').save({ adapterOptions: { activityId: activityId } });
          } else {
            var offerId = _this15.controller.get('offer.id');
            return _this15.controller.get('offer').save({ adapterOptions: { offerId: offerId, activityId: activityId } });
          }
        }).then(function (offer) {
          if (_this15.get('featureFlags').checkEnabled('MultipleOptionsGroup') && offer.get('isMultipleOptionGroup')) {
            _this15.groupSchedulesAndCommentary(offer);
            //Force a re-render of schedules to fix the issue with languages not updating correctly
            _this15.controller.set('showGroupSchedulesAndCommentary', false);
            Ember.run.next(function () {
              _this15.controller.set('showGroupSchedulesAndCommentary', true);
            });
          }
          _this15.controller.set('offer.setupComplete', true);
          _this15.controller.set('dirty', false);
          _this15.controller.set('offer.editingSetup', false);
          _this15.controller.set('saving', false);
          var cancellationHourCutOff = offer.get('cancellationCutoffInHours');
          _this15.controller.set('prevCancellationCutOffHours', cancellationHourCutOff);
          var cancellationType = offer.get('cancellationPolicyType');
          _this15.controller.set('prevCancellationType', cancellationType);
          CKEDITOR.instances['lx-ckeditor textarea-offer-description'].setReadOnly(true);
          _this15.disableFields();
          if (_this15.controller.get('activity.isActivityPublished') && _this15.controller.get('hasAttrsToPublishChanged')) {
            var activity = _this15.controller.get('activity');
            _this15.publishChanges(activity);
          } else {
            _this15.controller.set('saveStep', 1);
            _this15.get('notifications').success(_this15.get('i18n').t('components.alert.saveSuccess'));
          }
          window.scrollTo(0, 0);
          var lastSavedOfferAttrs = {
            isVbp: _this15.controller.get('offer.offerAttributes.isPriceByVolume'),
            isNew: isNew,
            isMultipleOptionGroup: offer.get('isMultipleOptionGroup'),
            offerOrGroupId: offer.get('isMultipleOptionGroup') === true ? offer.id : offer.get('id')
          };
          localStorage.setItem('lastSavedOfferAttrs', JSON.stringify(lastSavedOfferAttrs));
          if (isNew) {
            _this15.controller.set('savedNewOffer', true);
            if (offer.get('isMultipleOptionGroup') === true) {
              var offerGroupId = offer.id;
              _this15.transitionTo('activity.offer', offerGroupId, { queryParams: { isOfferGroup: true } });
            } else {
              _this15.transitionTo('activity.offer', offer.get('id'), { queryParams: { isOfferGroup: false } });
            }
          } else {
            _this15.controller.set('updatedOffer', true);
          }
        }).catch(function (error) {
          _this15.controller.set('saving', false);
          _this15.controller.set('errors', error);
          Ember.Logger.error('error saving offer', error);
          (0, _formValidator.scrollToFirstError)();
          _this15.controller.set('updatedOffer', false);
        });
      },
      updatePrices: function updatePrices() {
        var _this16 = this;

        var offerId = this.controller.get('offer.id');
        var isMultipleOptionGroup = this.controller.get('offer.isMultipleOptionGroup');
        this.controller.set('lastUpdated', 'pricingIngestion');

        var data = {};
        var url = void 0;
        if (isMultipleOptionGroup) {
          url = _environment.default.api.host + '/api/v3/offerGroup/' + offerId + '/triggerPricingIngestion';
        } else {
          url = _environment.default.api.host + '/api/v3/offer/' + offerId + '/triggerPricingIngestion';
        }
        this.get('ajaxWrapper').ajaxPut(url, data).then(function () {
          _this16.get('notifications').success(_this16.get('i18n').t('pages.offer.pricingIngestion.alert.success'));
        }).catch(function () {
          _this16.get('notifications').error(_this16.get('i18n').t('pages.offer.pricingIngestion.alert.error'));
        }).finally(function () {
          _this16.controller.set('saveStep', 2);
        });
      },
      savePriceGroup: function savePriceGroup(priceGroup, pgIndex) {
        var _this17 = this;

        var offerId = this.controller.get('offer.id');
        this.controller.set('lastUpdated', 'priceGroup');
        this.removeDispersedTickets(priceGroup);
        var isNewPg = priceGroup.get('isNew');
        this.validatePriceFields(priceGroup).then(function () {
          _this17.controller.set('savingPg', true);
          var offer = _this17.controller.get('offer');
          var isMultipleOptionGroup = offer.get('isMultipleOptionGroup');
          var isLimitedInventory = offer.get('isLimitedInventory');
          var isStartTime = offer.get('offerAttributes.scheduleType') === 'Start';
          var isMultiDay = offer.get('offerAttributes.scheduleType') === 'Multi-day';
          // this is only to fix the specific case for editing start time && limited inventory
          // further discussion is pending to refactor this
          if (isLimitedInventory && (isStartTime || isMultiDay)) {
            priceGroup.get('availabilities').forEach(function (availabilities) {
              availabilities.get('availability').forEach(function (dayAvail) {
                var isAvailable = dayAvail.get('capacity') > 0;
                dayAvail.set('available', isAvailable);
              });
            });
          }

          var currentPg = _this17.controller.get('currentPg');
          return priceGroup.save({
            adapterOptions: {
              offerId: offerId,
              oldPg: currentPg,
              isOfferGroup: isMultipleOptionGroup,
              isLimitedInventory: isLimitedInventory,
              travelStartPrev: _this17.controller.get('travelStartPrev'),
              travelEndPrev: _this17.controller.get('travelEndPrev')
            } });
        }).then(function (priceGroup) {
          //ember isn't updating id's of new price (ticket-details)
          //and creates new ones
          //this removes the stale prices (ticket-detail) after save
          var pricesToRemove = priceGroup.get('prices').map(function (price) {
            if (!price.get('id')) {
              return price;
            }
          }).compact();
          pricesToRemove.forEach(function (priceToRemove) {
            return priceToRemove.rollbackAttributes();
          });
          _this17.controller.set('hasSavedPriceGroups', true);
          _this17.controller.set('editingPg', false);
          _this17.controller.set('editingPgIndex', null);
          _this17.controller.set('dirty', false);

          _this17.controller.set('saveStep', 2);
          _this17.get('notifications').success(_this17.get('i18n').t('components.alert.saveSuccess'));
          if (_this17.get('featureFlags').checkEnabled('PriceUpdate') && !isNewPg) {
            priceGroup.set('priceUpdateAlertVisible', true);
          }
        }).catch(function (pricingErrors) {
          Ember.Logger.error('error saving price group', pricingErrors);
          _this17.controller.set('editingPgIndex', pgIndex);
          _this17.controller.set('pricingErrors', Object.assign({}, pricingErrors));
          (0, _formValidator.scrollToFirstError)();
        }).finally(function () {
          _this17.controller.set('savingPg', false);
        });
      },
      editPriceGroup: function editPriceGroup(pgIndex, pg) {
        this.controller.set('editingPg', true);
        this.controller.set('currentPg', pg);
        this.controller.set('travelStartPrev', pg.get('travelStart').toString());
        this.controller.set('travelEndPrev', pg.get('travelEnd').toString());
        this.controller.set('editingPgIndex', pgIndex);
      },
      cancelEditingPg: function cancelEditingPg() {
        this.controller.set('editingPg', false);
        this.controller.set('editingPgIndex', null);
        this.controller.set('dirty', false);

        if (this.controller.get('currentPage') > this.controller.get('totalPages')) {
          this.controller.goToPage(1);
        }
      },
      setTimeFormat: function setTimeFormat(format) {
        this.controller.set('timeFormat', format);
      },
      willTransition: function willTransition() {
        //reset platform name
        var activityExternalRefRes = this.controllerFor('activity').get('activityExternalRefRes');
        this.get('nameInHeader').setPartnerActivityOrOfferId(activityExternalRefRes, activityExternalRefRes.partnerActivityId);

        if (this.controller.get('editingPg')) {
          var pgIdx = this.controller.get('editingPgIndex');
          var pg = this.controller.get('offer.prices').objectAt(pgIdx);

          if (pg) {
            var isNew = pg.get('isNew');

            if (isNew) {
              pg.destroyRecord();
            } else {
              pg.rollbackAttributes();
            }
          }
        }
        //for tests
        delete window.pageName;
        // this.resetToInitialState();
      },
      didTransition: function didTransition() {
        //for tests
        window.pageName = 'offer';
        this.controller.set('errors', '');
        this.controller.set('pricingErrors', '');
        this.controller.set('editingPgIndex', null);
        this.controller.set('saving', false);
        this.controller.set('savingTicket', false);
        this.controller.set('savingPg', false);
        this.controller.set('savingMf', false);
        return true;
      }
    },
    _updateTitlePrefix: function _updateTitlePrefix() {
      var type = this.controller.get('offer.offerAttributes.scheduleType');
      var durationInMinutes = this.controller.get('offer.durationInMinutes');
      var startTime = this.controller.get('offer.offerAttributes.startTime');
      if (type === 'Start' || type === 'Multi-day') {
        if (startTime) {
          var hours = startTime.slice(0, 2);
          var minutes = startTime.slice(2);

          if (hours > 12) {
            this.controller.set('titlePrefix', hours - 12 + minutes + ' PM');
          } else if (hours == 12) {
            this.controller.set('titlePrefix', hours + minutes + ' PM');
          } else if (hours == '00') {
            this.controller.set('titlePrefix', '12' + minutes + ' AM');
          } else {
            var noLeadingZero = startTime.replace(/^0+/, '');
            this.controller.set('titlePrefix', noLeadingZero + ' AM');
          }
        } else {
          this.controller.set('titlePrefix', '--:-- --');
        }
      } else if (durationInMinutes && type != 'Hours of Operation') {
        var hoursDuration = durationInMinutes / 60;
        var daysDuration = hoursDuration / 24;
        if (type === 'Valid Days') {
          this.controller.set('titlePrefix', daysDuration + '-Day');
        } else if (type === 'Valid Hours') {
          var pluralOrNotHours = hoursDuration > 1 ? ' Hours' : ' Hour';
          this.controller.set('titlePrefix', 'Valid for ' + (hoursDuration + pluralOrNotHours));
        }
      } else {
        this.controller.set('titlePrefix', '');
      }
    },
    _usageDaysChange: function _usageDaysChange(days) {
      var daysInt = parseInt(days);
      this.controller.set('offer.offerAttributes.usageDays', daysInt);
      this.controller.set('usageDaysCheckboxClicked', false);
    },
    _setUsageDaysDropDown: function _setUsageDaysDropDown(days) {
      var daysInt = parseInt(days);
      var upperLimit = 90;
      var possibleUsageDays = (0, _arrayValuesHigher.getArrayOfValuesHigher)(daysInt, upperLimit);
      this.controller.set('usageDaysOptions', possibleUsageDays);
      this.controller.set('usageDaysValues', possibleUsageDays);
    },
    _daysChange: function _daysChange(days) {
      var _this18 = this;

      var daysInt = parseInt(days);
      this.controller.set('selectedDays', daysInt);
      this.controller.set('offer.durationInMinutes', daysInt * 24 * 60);
      Ember.run.next(function () {
        _this18.send('updateTitlePrefix');
      });
    }
  });
});