define('lxso/utils/ticket-type-validator', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = validateTicketType;
  function validateTicketType(editingTicketTypes, ticketTypeDisplayNames, index, selectedValue, showAlert) {
    console.log('Index: ' + index + ', Selected Value: ' + selectedValue);

    // Check for valid index and editingTicketTypes
    if (!editingTicketTypes || index >= editingTicketTypes.length) {
      console.error('Editing ticket types are not defined or index ' + index + ' is out of bounds.');
      return;
    }

    // Fetch the current ticket type
    var currentTicketType = editingTicketTypes.objectAt(index);
    if (!currentTicketType) {
      console.error('Ticket type at index ' + index + ' not found.');
      return;
    }

    // Handle empty selection
    if (!selectedValue) {
      console.warn('Selected value is empty.');
      Ember.set(currentTicketType, 'selectedOption', ''); // Clear selection
      return;
    }

    // Check for duplicates and show alert
    var selectedTicketTypes = editingTicketTypes.mapBy('selectedOption');
    /* if (selectedTicketTypes.includes(selectedValue) && currentTicketType.selectedOption !== selectedValue) {
       // Use the display name for the alert
       const displayName = ticketTypeDisplayNames ? ticketTypeDisplayNames[selectedValue] : selectedValue;
       // alert(`${displayName} is already selected! Please choose another type.`);
       showAlert(`${displayName} is already selected! Please choose another type.`);
        // Clear current selection and set to "Select a ticket type"
       Ember.set(currentTicketType, 'selectedOption', ''); // Clear current selection
     } else {
       Ember.set(currentTicketType, 'selectedOption', selectedValue); // Update current selection
     }*/

    // Check if the selected value is already in the list of selected options (contains logic)
    var isDuplicate = selectedTicketTypes.some(function (ticketType) {
      return ticketType.toLowerCase().includes(selectedValue.toLowerCase()) && ticketType !== selectedValue;
    } // Ensure we are not checking against the same selected value
    );

    if (isDuplicate) {
      // Use the display name for the alert
      var displayName = ticketTypeDisplayNames ? ticketTypeDisplayNames[selectedValue] : selectedValue;
      showAlert(displayName + ' is already selected! Please choose another type.');

      // Clear current selection and set to "Select a ticket type"
      Ember.set(currentTicketType, 'selectedOption', ''); // Clear current selection
    } else {
      // No duplicates found, set the selected value
      Ember.set(currentTicketType, 'selectedOption', selectedValue); // Update current selection
    }
  }
});