define('lxso/helpers/is-not-equal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isNotEqual = isNotEqual;
  function isNotEqual(params /*, hash*/) {
    return params[0] !== params[1];
  }

  exports.default = Ember.Helper.helper(isNotEqual);
});