define('lxso/routes/activity/mandatory-fees-upload', ['exports', 'lxso/mixins/example-modal'], function (exports, _exampleModal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_exampleModal.default, {
    i18n: Ember.inject.service(),
    session: Ember.inject.service('session'),

    init: function init() {
      this._super.apply(this, arguments);
    },
    model: function model(params) {
      var activityId = this.modelFor('activity').get('id');
      console.log('activityId..12..', activityId);
      // const mandatoryFeesUploadInfo = this.store.findRecord('mandatory-fees-upload', activityId, {reload: true});
      return new Ember.RSVP.hash({
        activityId: activityId
      });
    },
    setupController: function setupController(controller, model) {
      // controller.set('route', this);
      // this._super(controller, model.params);
      this.controller.set('activity', this.modelFor('activity'));
    },


    actions: {
      continue: function _continue() {
        console.log('Inside continue');
        var route = this;
        var activity = route.controller.get('activity');
        console.log('Route Inside continue route', route);
        if (this.controller.get('activity').get('migrationStatus.offer')) {
          console.log('inside migrationStatus.offer if condition');
          route.transitionTo('activity.option-group', this.controller.get('activity').get('id'));
        } else {
          console.log('inside migrationStatus.offer else condition');
          route.transitionTo('activity.offer-list', this.controller.get('activity').get('id'));
        }
      }
    }
  });
});