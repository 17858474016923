define('lxso/components/side-nav/component', ['exports', 'lxso/config/environment', 'npm:showdown', 'lxso/utils/omniture'], function (exports, _environment, _npmShowdown, _omniture) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['side-nav'],

    i18n: Ember.inject.service(),
    ajaxWrapper: Ember.inject.service(),
    featureFlags: Ember.inject.service(),
    permissions: Ember.inject.service(),
    publishValidationService: Ember.inject.service('publish-validation'),
    router: Ember.inject.service(),
    links: {
      overview: 'activity.overview',
      details: 'activity.details',
      photos: 'activity.photos',
      photosAndVideos: 'activity.photos',
      location: 'activity.location',
      policiesAndRedemption: 'activity.customer-service',
      ticketSetup: 'activity.ticket-setup',
      optionsSchedulesAndPrices: 'activity.tour-options',
      offerList: 'activity.offer-list',
      offerListQuickView: 'activity.offer-list',
      optionGroupList: 'activity.option-group',
      healthAndSafety: 'activity.health-and-safety',
      mandatoryFeesUpload: 'activity.mandatory-fees-upload'
    },

    listingLabelNamesOld: ['overview', 'details', 'photosAndVideos', 'location', 'policiesAndRedemption'],

    listingLabelNames: ['overview', 'details', 'photosAndVideos', 'location', 'healthAndSafety', 'policiesAndRedemption', 'mandatoryFeesUpload'],

    listingLabelNamesWithoutMandatoryFees: ['overview', 'details', 'photosAndVideos', 'location', 'healthAndSafety', 'policiesAndRedemption'],

    bookingLabelNames: ['ticketSetup', 'optionsSchedulesAndPrices'],

    offerLabelNames: ['offerList'],

    offerQuickViewLabelNames: ['offerListQuickView'],

    labelCompletenessList: {},

    init: function init() {
      this._super.apply(this, arguments);
      this.set('currentRoute', this.get('router.currentRouteName'));
    },

    routeObserver: Ember.observer('router.currentRouteName', function () {
      this.set('currentRoute', this.get('router.currentRouteName'));
    }),

    didReceiveAttrs: function didReceiveAttrs() {
      this.updateSteps();
    },
    generateLabel: function generateLabel(labelName) {
      return this.get('i18n').t('components.sideNav.steps.' + labelName).string;
    },
    generateLink: function generateLink(labelName) {
      return this.get('links')[labelName];
    },
    updateSteps: function updateSteps() {
      var _this = this;

      //generate steps also calculates if to show the go to publish button
      var offerLabelNames = Ember.A([]);
      if (this.get('activity').get('migrationStatus.offer')) {
        if (!this.get('listingLabelNames').includes('optionGroupList')) {
          this.get('listingLabelNames').push('optionGroupList');
          this.get('listingLabelNamesWithoutMandatoryFees').push('optionGroupList');
        }
      } else {
        this.get('listingLabelNames').removeObject('optionGroupList');
        if (this.get('featureFlags').checkEnabled('OptionsQuickView')) {
          offerLabelNames = this.get('offerQuickViewLabelNames');
        } else {
          offerLabelNames = this.get('offerLabelNames');
        }
      }
      var activityId = this.get('activity.id');
      var listingLabelNames = void 0;
      if (this.get('activity.isHealthAndSafetyEnabled')) {
        if (this.get('activity.isMandatoryFeesEnabled')) {
          console.log('activity.isMandatoryFeesEnabled inside if, activity level', this.get('activity.isMandatoryFeesEnabled'));
          listingLabelNames = this.get('listingLabelNames');
        } else {
          console.log('activity.isMandatoryFeesEnabled inside else, activity level', this.get('activity.isMandatoryFeesEnabled'));
          listingLabelNames = this.get('listingLabelNamesWithoutMandatoryFees');
        }
      } else {
        listingLabelNames = this.get('listingLabelNamesOld');
      }
      //
      // //do this quickly first to generate the links,
      // //otherwise when mediavault is down, the sidebar
      // //takes a very long time to load
      this.generateSteps(listingLabelNames, 'listingSteps');
      this.generateSteps(offerLabelNames, 'offerSteps', listingLabelNames.length);

      if (!this.get('activity').get('isActivityPublished')) {
        this.get('publishValidationService').getPublishValidation(this.get('activity')).then(function (publishValidation) {
          _this.set('publishValidation', publishValidation);

          _this.get('activity').get('assets').reload().finally(function () {
            _this.generateSteps(listingLabelNames, 'listingSteps');
            _this.generateSteps(offerLabelNames, 'offerSteps', listingLabelNames.length);
          });
        });
      }
    },
    isComplete: function isComplete(labelName) {
      var activity = this.get('activity');
      var isGroundTransfer = activity.get('isViewOnlyForGroundTransfer');
      switch (labelName) {
        case 'overview':
          {
            return this.get('publishValidation.hasDescription') && (this.get('publishValidation.hasHighlights') || isGroundTransfer);
          }
        case 'details':
          {
            return (this.get('publishValidation.hasInclusions') || isGroundTransfer) && this.get('publishValidation.hasKnowBeforeYouBook') && this.get('publishValidation.hasKnowBeforeYouGo');
          }
        case 'photos':
        case 'photosAndVideos':
          {
            var hasOldPhotos = activity.get('assets').get('content').length > 0;
            return this.get('publishValidation.hasPhoto') || hasOldPhotos;
          }
        case 'location':
          {
            return this.get('publishValidation.hasLocation');
          }
        case 'policiesAndRedemption':
          {
            return this.get('publishValidation.hasContactInformation') && (this.get('publishValidation.hasRedemptionInstructions') || isGroundTransfer);
          }
        case 'offerList':
          {
            return this.get('publishValidation.hasPricing');
          }
        case 'offerListQuickView':
          {
            return this.get('publishValidation.hasPricing');
          }
        case 'optionGroupList':
          {
            return this.get('publishValidation.hasPricing');
          }
        case 'healthAndSafety':
          {
            return this.get('publishValidation.hasHealthAndSafetyInfo');
          }
      }
    },


    //generate steps also calculates if to show the go to publish button
    generateSteps: function generateSteps(labels, listName) {
      var _this2 = this;

      var offset = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;

      var labelPromises = labels.map(function (labelName, index) {
        _this2.addToCompletedList(labelName, _this2.isComplete(labelName));

        return {
          label: _this2.generateLabel(labelName),
          link: _this2.generateLink(labelName),
          complete: _this2.isComplete(labelName),
          current: index + offset
        };
      });

      Ember.RSVP.map(labelPromises, function (t) {
        return t;
      }).then(function (labelPromiseResults) {
        _this2.set(listName, labelPromiseResults);
      });
    },
    setTicketType: function setTicketType(activityId, resolve) {
      var data = {};
      var url = _environment.default.api.host + '/' + _environment.default.api.namespace + '/activities/' + activityId + '/ticket_types';
      this.get('ajaxWrapper').ajaxGet(url, data).then(function (result) {
        resolve(result.length > 0);
      });
    },
    setOptionDone: function setOptionDone(activity, resolve) {
      var _this3 = this;

      var url = void 0;
      activity.get('options').reload().then(function (options) {
        var optionsPromises = options.filterBy('status', 'ACTIVE').map(function (option) {
          url = _environment.default.api.host + '/' + _environment.default.api.namespace + '/offerpriceavailability/option/' + option.get('id');
          return _this3.get('ajaxWrapper').ajaxGet(url).then(function (opas) {
            return opas.length > 0;
          });
        });
        Ember.RSVP.all(optionsPromises).then(function (results) {
          var areOffers = results.some(function (result) {
            return result === true;
          });
          resolve(areOffers);
        });
      });
    },
    addToCompletedList: function addToCompletedList(labelName, completeness) {
      if (!this.get('activity.isActivityPublished')) {
        this.get('labelCompletenessList')[labelName] = completeness;
        this.set('sectionsComplete', true);
        for (var section in this.get('labelCompletenessList')) {
          if (this.get('labelCompletenessList')[section] == false) {
            this.set('sectionsComplete', false);
          }
        }
        this.set('showGoToPublish', this.get('sectionsComplete') && this.get('current-step') != 'publish');
      } else {
        this.set('showGoToPublish', false);
      }
    },


    actions: {
      goToPublish: function goToPublish() {
        // Don't do this often, however with the side nav
        // being placed in every activity child route it
        // is more efficient than mixing in a go to publish
        // function in every route
        Ember.getOwner(this).lookup('router:main').transitionTo('activity.publish');
      },
      openPreview: function openPreview() {
        var omnitureValue = 'page.LX-Supply.ViewPreview';
        (0, _omniture.sendOmnitureDataOnClick)(omnitureValue);
        this.sendAction('openPreview');
      },
      goToManageAvailability: function goToManageAvailability(supplierBranchId) {
        var omnitureValue = 'page.LX-Supply.AvailabilityMgr';
        (0, _omniture.sendOmnitureDataOnClick)(omnitureValue);

        //Cannot use transitionTo directly since we need the page to open in a new tab
        var url = Ember.getOwner(this).lookup('router:main').generate('manage-availability', supplierBranchId);
        window.open(url);
      },
      sendOmnitureDataForNavItem: function sendOmnitureDataForNavItem(itemName) {
        var omnitureValue = 'page.LX-Supply.' + itemName.replace(/ /g, '');
        (0, _omniture.sendOmnitureDataOnClick)(omnitureValue);
      }
    }
  });
});