define('lxso/helpers/format-amount', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatAmount = formatAmount;
  function formatAmount(params /*, hash*/) {
    var amount = params[0];

    // Format the amount to two decimal places
    return '' + parseFloat(amount).toFixed(2);
  }

  exports.default = Ember.Helper.helper(formatAmount);
});