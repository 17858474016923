define('lxso/controllers/activity/option-group/group', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    isAdmissionFeesDialogOpen: false,
    isDockFeesDialogOpen: false,
    isFuelFeesDialogOpen: false,
    isInsuranceFeesDialogOpen: false,
    isAccommodationFeesDialogOpen: false,
    isTransportFeesDialogOpen: false,
    isSoloFeesDialogOpen: false,
    isHolidayFeesDialogOpen: false,
    isMiscellaneousFeesDialogOpen: false,
    isEquipmentFeesDialogOpen: false,
    isPhotographyFeesDialogOpen: false,
    isMarriageFeesDialogOpen: false,
    isLicenceFeesDialogOpen: false,
    isTastingFeesDialogOpen: false,
    isCustomsFeesDialogOpen: false,
    isVisaFeesDialogOpen: false,
    isGovernmentFeesDialogOpen: false,
    isEditMF: false,
    isAlertVisible: false,
    alertMessage: '',
    isFileUploadedSuccessfully: false,

    actions: {
      showAlert: function showAlert(message) {
        console.log('inside showAlert...');
        this.set('alertMessage', message);
        this.set('isAlertVisible', true);
        this.notifyPropertyChange('isAlertVisible');
      },
      hideAlert: function hideAlert() {
        this.set('isAlertVisible', false);
        if (this.get('isFileUploadedSuccessfully')) {
          window.location.reload();
        }
      },
      editMF: function editMF() {
        console.log('inside editMF - Controller');
        this.set('isEditMF', true); // Set the property on the controller
      },
      openAdmissionFeesDialog: function openAdmissionFeesDialog() {
        console.log('Opening dialog with data controller');
        this.set('isAdmissionFeesDialogOpen', true);
      },
      openDockFeesDialog: function openDockFeesDialog() {
        console.log('Opening dialog with data controller');
        this.set('isDockFeesDialogOpen', true);
      },
      openFuelFeesDialog: function openFuelFeesDialog() {
        console.log('Opening dialog with data controller');
        this.set('isFuelFeesDialogOpen', true);
      },
      openInsuranceFeesDialog: function openInsuranceFeesDialog() {
        console.log('Opening dialog with data controller');
        this.set('isInsuranceFeesDialogOpen', true);
      },
      openAccommodationFeesDialog: function openAccommodationFeesDialog() {
        console.log('Opening dialog with data controller');
        this.set('isAccommodationFeesDialogOpen', true);
      },
      openTransportFeesDialog: function openTransportFeesDialog() {
        console.log('Opening dialog with data controller');
        this.set('isTransportFeesDialogOpen', true);
      },
      openSoloFeesDialog: function openSoloFeesDialog() {
        console.log('Opening dialog with data controller');
        this.set('isSoloFeesDialogOpen', true);
      },
      openHolidayFeesDialog: function openHolidayFeesDialog() {
        console.log('Opening dialog with data controller');
        this.set('isHolidayFeesDialogOpen', true);
      },
      openMiscellaneousFeesDialog: function openMiscellaneousFeesDialog() {
        console.log('Opening dialog with data controller');
        this.set('isMiscellaneousFeesDialogOpen', true);
      },
      openEquipmentFeesDialog: function openEquipmentFeesDialog() {
        console.log('Opening dialog with data controller');
        this.set('isEquipmentFeesDialogOpen', true);
      },
      openPhotographyFeesDialog: function openPhotographyFeesDialog() {
        console.log('Opening dialog with data controller');
        this.set('isPhotographyFeesDialogOpen', true);
      },
      openMarriageFeesDialog: function openMarriageFeesDialog() {
        console.log('Opening dialog with data controller');
        this.set('isMarriageFeesDialogOpen', true);
      },
      openLicenceFeesDialog: function openLicenceFeesDialog() {
        console.log('Opening dialog with data controller');
        this.set('isLicenceFeesDialogOpen', true);
      },
      openTastingFeesDialog: function openTastingFeesDialog() {
        console.log('Opening dialog with data controller');
        this.set('isTastingFeesDialogOpen', true);
      },
      openCustomsFeesDialog: function openCustomsFeesDialog() {
        console.log('Opening dialog with data controller');
        this.set('isCustomsFeesDialogOpen', true);
      },
      openVisaFeesDialog: function openVisaFeesDialog() {
        console.log('Opening dialog with data controller');
        this.set('isVisaFeesDialogOpen', true);
      },
      openGovernmentFeesDialog: function openGovernmentFeesDialog() {
        console.log('Opening dialog with data controller');
        this.set('isGovernmentFeesDialogOpen', true);
      },
      closeAdmissionFeesDialog: function closeAdmissionFeesDialog() {
        Ember.Logger.log('Closing dialog');
        this.set('isAdmissionFeesDialogOpen', false);
      },
      closeDockFeesDialog: function closeDockFeesDialog() {
        Ember.Logger.log('Closing dialog');
        this.set('isDockFeesDialogOpen', false);
      },
      closeFuelFeesDialog: function closeFuelFeesDialog() {
        Ember.Logger.log('Closing dialog');
        this.set('isFuelFeesDialogOpen', false);
      },
      closeInsuranceFeesDialog: function closeInsuranceFeesDialog() {
        Ember.Logger.log('Closing dialog');
        this.set('isInsuranceFeesDialogOpen', false);
      },
      closeAccommodationFeesDialog: function closeAccommodationFeesDialog() {
        Ember.Logger.log('Closing dialog');
        this.set('isAccommodationFeesDialogOpen', false);
      },
      closeTransportFeesDialog: function closeTransportFeesDialog() {
        Ember.Logger.log('Closing dialog');
        this.set('isTransportFeesDialogOpen', false);
      },
      closeSoloFeesDialog: function closeSoloFeesDialog() {
        Ember.Logger.log('Closing dialog');
        this.set('isSoloFeesDialogOpen', false);
      },
      closeHolidayFeesDialog: function closeHolidayFeesDialog() {
        Ember.Logger.log('Closing dialog');
        this.set('isHolidayFeesDialogOpen', false);
      },
      closeMiscellaneousFeesDialog: function closeMiscellaneousFeesDialog() {
        Ember.Logger.log('Closing dialog');
        this.set('isMiscellaneousFeesDialogOpen', false);
      },
      closeEquipmentFeesDialog: function closeEquipmentFeesDialog() {
        Ember.Logger.log('Closing dialog');
        this.set('isEquipmentFeesDialogOpen', false);
      },
      closePhotographyFeesDialog: function closePhotographyFeesDialog() {
        Ember.Logger.log('Closing dialog');
        this.set('isPhotographyFeesDialogOpen', false);
      },
      closeMarriageFeesDialog: function closeMarriageFeesDialog() {
        Ember.Logger.log('Closing dialog');
        this.set('isMarriageFeesDialogOpen', false);
      },
      closeLicenceFeesDialog: function closeLicenceFeesDialog() {
        Ember.Logger.log('Closing dialog');
        this.set('isLicenceFeesDialogOpen', false);
      },
      closeTastingFeesDialog: function closeTastingFeesDialog() {
        Ember.Logger.log('Closing dialog');
        this.set('isTastingFeesDialogOpen', false);
      },
      closeCustomsFeesDialog: function closeCustomsFeesDialog() {
        Ember.Logger.log('Closing dialog');
        this.set('isCustomsFeesDialogOpen', false);
      },
      closeVisaFeesDialog: function closeVisaFeesDialog() {
        Ember.Logger.log('Closing dialog');
        this.set('isVisaFeesDialogOpen', false);
      },
      closeGovernmentFeesDialog: function closeGovernmentFeesDialog() {
        Ember.Logger.log('Closing dialog');
        this.set('isGovernmentFeesDialogOpen', false);
      },
      saveAdmissionFees: function saveAdmissionFees(updatedFees, removedTicketTypesAdmission) {
        Ember.Logger.log('Saving admission fees', updatedFees);

        // Check if ticketTypes is included in updatedFees
        if (updatedFees.ticketTypes && updatedFees.ticketTypes.length > 0) {
          Ember.Logger.log('Ticket Types:', updatedFees.ticketTypes);
        } else {
          Ember.Logger.warn('No ticket types found!');
        }

        this.set('admissionFees', updatedFees);
        this.set('removedTicketTypesAdmission', removedTicketTypesAdmission);
        this.set('isAdmissionFeesDialogOpen', false);
      },
      saveDockFees: function saveDockFees(updatedFees, removedTicketTypesDock) {
        Ember.Logger.log('Saving dock fees', updatedFees);
        Ember.Logger.log('removedTicketTypesDock Saving dock fees ', removedTicketTypesDock);

        // Check if ticketTypes is included in updatedFees
        if (updatedFees.ticketTypes && updatedFees.ticketTypes.length > 0) {
          Ember.Logger.log('Ticket Types:', updatedFees.ticketTypes);
        } else {
          Ember.Logger.warn('No ticket types found!');
        }

        this.set('dockFees', updatedFees);
        this.set('removedTicketTypesDock', removedTicketTypesDock);
        this.set('isDockFeesDialogOpen', false);
      },
      saveFuelFees: function saveFuelFees(updatedFees, removedTicketTypesFuel) {
        Ember.Logger.log('Saving fuel fees', updatedFees);

        // Check if ticketTypes is included in updatedFees
        if (updatedFees.ticketTypes && updatedFees.ticketTypes.length > 0) {
          Ember.Logger.log('Ticket Types:', updatedFees.ticketTypes);
        } else {
          Ember.Logger.warn('No ticket types found!');
        }

        this.set('fuelFees', updatedFees);
        this.set('removedTicketTypesFuel', removedTicketTypesFuel);
        this.set('isFuelFeesDialogOpen', false);
      },
      saveInsuranceFees: function saveInsuranceFees(updatedFees, removedTicketTypesInsurance) {
        Ember.Logger.log('Saving insurance fees', updatedFees);

        // Check if ticketTypes is included in updatedFees
        if (updatedFees.ticketTypes && updatedFees.ticketTypes.length > 0) {
          Ember.Logger.log('Ticket Types:', updatedFees.ticketTypes);
        } else {
          Ember.Logger.warn('No ticket types found!');
        }

        this.set('insuranceFees', updatedFees);
        this.set('removedTicketTypesInsurance', removedTicketTypesInsurance);
        this.set('isInsuranceFeesDialogOpen', false);
      },
      saveAccommodationFees: function saveAccommodationFees(updatedFees, removedTicketTypesAccommodation) {
        Ember.Logger.log('Saving insurance fees', updatedFees);

        // Check if ticketTypes is included in updatedFees
        if (updatedFees.ticketTypes && updatedFees.ticketTypes.length > 0) {
          Ember.Logger.log('Ticket Types:', updatedFees.ticketTypes);
        } else {
          Ember.Logger.warn('No ticket types found!');
        }

        this.set('accommodationFees', updatedFees);
        this.set('removedTicketTypesAccommodation', removedTicketTypesAccommodation);
        this.set('isAccommodationFeesDialogOpen', false);
      },
      saveTransportFees: function saveTransportFees(updatedFees, removedTicketTypesTransport) {
        Ember.Logger.log('Saving Transport fees', updatedFees);

        // Check if ticketTypes is included in updatedFees
        if (updatedFees.ticketTypes && updatedFees.ticketTypes.length > 0) {
          Ember.Logger.log('Ticket Types:', updatedFees.ticketTypes);
        } else {
          Ember.Logger.warn('No ticket types found!');
        }

        this.set('transportFees', updatedFees);
        this.set('removedTicketTypesTransport', removedTicketTypesTransport);
        this.set('isTransportFeesDialogOpen', false);
      },
      saveSoloFees: function saveSoloFees(updatedFees, removedTicketTypesSolo) {
        Ember.Logger.log('Saving insurance fees', updatedFees);

        // Check if ticketTypes is included in updatedFees
        if (updatedFees.ticketTypes && updatedFees.ticketTypes.length > 0) {
          Ember.Logger.log('Ticket Types:', updatedFees.ticketTypes);
        } else {
          Ember.Logger.warn('No ticket types found!');
        }

        this.set('soloFees', updatedFees);
        this.set('removedTicketTypesSolo', removedTicketTypesSolo);
        this.set('isSoloFeesDialogOpen', false);
      },
      saveHolidayFees: function saveHolidayFees(updatedFees, removedTicketTypesHoliday) {
        Ember.Logger.log('Saving holiday fees', updatedFees);

        // Check if ticketTypes is included in updatedFees
        if (updatedFees.ticketTypes && updatedFees.ticketTypes.length > 0) {
          Ember.Logger.log('Ticket Types:', updatedFees.ticketTypes);
        } else {
          Ember.Logger.warn('No ticket types found!');
        }

        this.set('holidayFees', updatedFees);
        this.set('removedTicketTypesHoliday', removedTicketTypesHoliday);
        this.set('isHolidayFeesDialogOpen', false);
      },
      saveMiscellaneousFees: function saveMiscellaneousFees(updatedFees, removedTicketTypesMiscellaneous) {
        Ember.Logger.log('Saving Miscellaneous fees', updatedFees);

        // Check if ticketTypes is included in updatedFees
        if (updatedFees.ticketTypes && updatedFees.ticketTypes.length > 0) {
          Ember.Logger.log('Ticket Types:', updatedFees.ticketTypes);
        } else {
          Ember.Logger.warn('No ticket types found!');
        }

        this.set('miscellaneousFees', updatedFees);
        this.set('removedTicketTypesMiscellaneous', removedTicketTypesMiscellaneous);
        this.set('isMiscellaneousFeesDialogOpen', false);
      },
      saveEquipmentFees: function saveEquipmentFees(updatedFees, removedTicketTypesEquipment) {
        Ember.Logger.log('Saving Equipment fees', updatedFees);

        // Check if ticketTypes is included in updatedFees
        if (updatedFees.ticketTypes && updatedFees.ticketTypes.length > 0) {
          Ember.Logger.log('Ticket Types:', updatedFees.ticketTypes);
        } else {
          Ember.Logger.warn('No ticket types found!');
        }

        this.set('equipmentFees', updatedFees);
        this.set('removedTicketTypesEquipment', removedTicketTypesEquipment);
        this.set('isEquipmentFeesDialogOpen', false);
      },
      savePhotographyFees: function savePhotographyFees(updatedFees, removedTicketTypesPhotography) {
        Ember.Logger.log('Saving Equipment fees', updatedFees);

        // Check if ticketTypes is included in updatedFees
        if (updatedFees.ticketTypes && updatedFees.ticketTypes.length > 0) {
          Ember.Logger.log('Ticket Types:', updatedFees.ticketTypes);
        } else {
          Ember.Logger.warn('No ticket types found!');
        }

        this.set('photographyFees', updatedFees);
        this.set('removedTicketTypesPhotography', removedTicketTypesPhotography);
        this.set('isPhotographyFeesDialogOpen', false);
      },
      saveMarriageFees: function saveMarriageFees(updatedFees, removedTicketTypesMarriage) {
        Ember.Logger.log('Saving Marriage fees', updatedFees);

        // Check if ticketTypes is included in updatedFees
        if (updatedFees.ticketTypes && updatedFees.ticketTypes.length > 0) {
          Ember.Logger.log('Ticket Types:', updatedFees.ticketTypes);
        } else {
          Ember.Logger.warn('No ticket types found!');
        }

        this.set('marriageFees', updatedFees);
        this.set('removedTicketTypesMarriage', removedTicketTypesMarriage);
        this.set('isMarriageFeesDialogOpen', false);
      },
      saveLicenceFees: function saveLicenceFees(updatedFees, removedTicketTypesLicence) {
        Ember.Logger.log('Saving Licence fees', updatedFees);

        // Check if ticketTypes is included in updatedFees
        if (updatedFees.ticketTypes && updatedFees.ticketTypes.length > 0) {
          Ember.Logger.log('Ticket Types:', updatedFees.ticketTypes);
        } else {
          Ember.Logger.warn('No ticket types found!');
        }

        this.set('licenceFees', updatedFees);
        this.set('removedTicketTypesLicence', removedTicketTypesLicence);
        this.set('isLicenceFeesDialogOpen', false);
      },
      saveTastingFees: function saveTastingFees(updatedFees, removedTicketTypesTasting) {
        Ember.Logger.log('Saving Tasting fees', updatedFees);

        // Check if ticketTypes is included in updatedFees
        if (updatedFees.ticketTypes && updatedFees.ticketTypes.length > 0) {
          Ember.Logger.log('Ticket Types:', updatedFees.ticketTypes);
        } else {
          Ember.Logger.warn('No ticket types found!');
        }

        this.set('tastingFees', updatedFees);
        this.set('removedTicketTypesTasting', removedTicketTypesTasting);
        this.set('isTastingFeesDialogOpen', false);
      },
      saveCustomsFees: function saveCustomsFees(updatedFees, removedTicketTypesCustoms) {
        Ember.Logger.log('Saving Tasting fees', updatedFees);

        // Check if ticketTypes is included in updatedFees
        if (updatedFees.ticketTypes && updatedFees.ticketTypes.length > 0) {
          Ember.Logger.log('Ticket Types:', updatedFees.ticketTypes);
        } else {
          Ember.Logger.warn('No ticket types found!');
        }

        this.set('customsFees', updatedFees);
        this.set('removedTicketTypesCustoms', removedTicketTypesCustoms);
        this.set('isCustomsFeesDialogOpen', false);
      },
      saveVisaFees: function saveVisaFees(updatedFees, removedTicketTypesVisa) {
        Ember.Logger.log('Saving Visa fees', updatedFees);

        // Check if ticketTypes is included in updatedFees
        if (updatedFees.ticketTypes && updatedFees.ticketTypes.length > 0) {
          Ember.Logger.log('Ticket Types:', updatedFees.ticketTypes);
        } else {
          Ember.Logger.warn('No ticket types found!');
        }

        this.set('visaFees', updatedFees);
        this.set('removedTicketTypesVisa', removedTicketTypesVisa);
        this.set('isVisaFeesDialogOpen', false);
      },
      saveGovernmentFees: function saveGovernmentFees(updatedFees, removedTicketTypesGovernment) {
        Ember.Logger.log('Saving Visa fees', updatedFees);

        // Check if ticketTypes is included in updatedFees
        if (updatedFees.ticketTypes && updatedFees.ticketTypes.length > 0) {
          Ember.Logger.log('Ticket Types:', updatedFees.ticketTypes);
        } else {
          Ember.Logger.warn('No ticket types found!');
        }

        this.set('governmentFees', updatedFees);
        this.set('removedTicketTypesGovernment', removedTicketTypesGovernment);
        this.set('isGovernmentFeesDialogOpen', false);
      },
      saveV4MandatoryFees: function saveV4MandatoryFees(combinedFees) {
        console.log('Controller is forwarding the action to the route');
        // Forward the action to the route
        this.send('publishV4MandatoryFees', combinedFees);
      },
      saveV4OptionalFees: function saveV4OptionalFees(combinedFees) {
        console.log('Controller is forwarding the action to the route');
        // Forward the action to the route
        this.send('publishV4OptionalFees', combinedFees);
      }
    }
  });
});