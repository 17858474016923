define('lxso/routes/promotion', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'lxso/mixins/require-supplier-branch', 'lxso/mixins/feature-flag', 'lxso/mixins/require-permission', 'lxso/mixins/promotion/promotion-creation-common', 'lxso/config/environment', 'lxso/utils/omniture'], function (exports, _authenticatedRouteMixin, _requireSupplierBranch, _featureFlag, _requirePermission, _promotionCreationCommon, _environment, _omniture) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _requireSupplierBranch.default, _promotionCreationCommon.default, {
    requiredPermission: 'PromotionRW',
    i18n: Ember.inject.service(),
    session: Ember.inject.service(),
    ajaxWrapper: Ember.inject.service(),
    permissions: Ember.inject.service(),
    routeHistory: Ember.inject.service(),
    featureFlags: Ember.inject.service(),
    queryParams: {
      productId: { refreshModel: true },
      optionId: { refreshModel: true },
      clone: { refreshModel: true },
      campaignId: { refreshModel: true }
    },

    clonePromo: function clonePromo(existigPromo, supplierBranchId, campaignId, campaignName) {
      return this.store.createRecord('promotion', {
        name: existigPromo.get('name'),
        supplierBranchId: supplierBranchId,
        status: 'Active',
        fundType: existigPromo.get('fundType'),
        funders: existigPromo.get('funders'),
        products: existigPromo.get('products'),
        discountTypeUI: existigPromo.get('expediaFundType') === 'Absolute' ? 'Absolute' : 'Percentage',
        expediaFundType: existigPromo.get('expediaFundType'),
        supplierFundType: existigPromo.get('supplierFundType'),
        totalDiscount: existigPromo.get('totalDiscount'),
        expediaFund: existigPromo.get('expediaFund'),
        supplierFund: existigPromo.get('supplierFund'),
        audiences: existigPromo.get('audiences'),
        shoppingPaths: existigPromo.get('shoppingPaths'),
        normalizedAudiences: existigPromo.get('normalizedAudiences'),
        travelStartDate: existigPromo.get('travelStartDate'),
        travelEndDate: existigPromo.get('travelEndDate'),
        bookingStartDate: existigPromo.get('bookingStartDate'),
        bookingEndDate: existigPromo.get('bookingEndDate'),
        promotionDetails: this.store.createFragment('fragments/promotion/promotion-details', {
          activities: existigPromo.get('promotionDetails.activities'),
          supplierBranchId: existigPromo.get('promotionDetails.supplierBranchId')
        }),
        campaignId: campaignId,
        campaignName: campaignName
      });
    },


    omniture: {
      pageName: 'page.LX-Supply.promotion'
    },

    model: function model(params) {
      var _this = this;

      var supplierBranchId = this.get('session.data.authenticated.selectedBranch.supplier_branch_id');
      var productListUrl = _environment.default.api.host + '/api/v3/supplierBranch/' + supplierBranchId + '/activities';
      var productList = this.get('ajaxWrapper').ajaxGet(productListUrl);

      var promotion = void 0;
      var promotionMetaData = void 0;
      var campaignId = void 0;
      var campaignName = void 0;

      if (params.promotionId === 'new') {
        if (params.campaignId !== null && params.campaignId !== undefined) {
          campaignId = params.campaignId;
          var promotionAndPromoMetaData = this.store.findRecord('campaign-meta-data', campaignId);
          promotionMetaData = promotionAndPromoMetaData.then(function (campaignData) {
            return campaignData.get('promotionMetaData');
          });
          promotion = promotionAndPromoMetaData.then(function (campaignData) {
            return _this.clonePromo(campaignData.get('promotion'), supplierBranchId, campaignId, campaignData.get('promotion').get('name'));
          }, promotionMetaData);
        } else {
          promotion = this.store.createRecord('promotion', {
            supplierBranchId: supplierBranchId,
            promotionDetails: this.store.createFragment('fragments/promotion/promotion-details', {
              activities: []
            })
          });
        }
      } else if (params.clone) {
        promotion = this.store.findRecord('promotion', params.promotionId).then(function (promoToBeCloned) {
          return _this.clonePromo(promoToBeCloned, supplierBranchId, campaignId, campaignName);
        });
      } else {
        promotion = this.store.findRecord('promotion', params.promotionId);
        if (this.get('featureFlags').checkEnabled('ManageCampaign')) {
          campaignId = promotion.then(function (promo) {
            return promo.get('campaignId');
          });
          var _promotionAndPromoMetaData = promotion.then(function (promo) {
            if (promo.get('campaignId') !== -1) {
              return _this.store.findRecord('campaign-meta-data', promo.get('campaignId'));
            }
          });
          if (_promotionAndPromoMetaData) {
            promotionMetaData = _promotionAndPromoMetaData.then(function (campaignData) {
              if (campaignData != undefined) {
                return campaignData.get('promotionMetaData');
              }
            });
          }
        }
      }
      return Ember.RSVP.hash({
        promotion: promotion,
        promotionMetaData: promotionMetaData,
        campaignId: campaignId,
        promotionId: params.promotionId,
        productList: productList
      });
    },
    setupController: function setupController(controller, model, transition) {
      var isNew = model.promotion.get('isNew');
      var isInternalUser = this.get('permissions').checkEnabled('V3InternalSettings');
      if (isNew) {
        this.controller.set('showPromotionWarnings', false);
      }
      controller.setProperties({
        promotion: model.promotion,
        promotionMetaData: model.promotionMetaData,
        campaignId: model.campaignId,
        errors: '',
        promotionId: model.promotionId,
        productList: model.productList.map(function (product) {
          return Ember.Object.create(Object.assign(product, {
            offers: product.offers.map(function (offer) {
              return Ember.Object.create(Object.assign(offer, {
                ticketTypes: offer.ticketTypes.map(function (ticket) {
                  return Ember.Object.create(ticket);
                })
              }));
            }) }));
        }),
        avaliableProductsAndOptions: Ember.computed(function () {
          var availableProducts = [];
          var availableOptions = [];
          model.productList.forEach(function (product) {
            availableProducts.push(product.id);
            product.offers.forEach(function (offer) {
              availableOptions.push(offer.id);
            });
          });
          return {
            products: availableProducts,
            options: availableOptions
          };
        }),
        isNew: isNew,
        anySectionOpen: Ember.computed.or('isPromoAudienceOpen', 'isPromoShoppingOpen', 'isPromoDateOpen', 'isPromoNameOpen', 'isPromoDetailsOpen'),
        currency: this.get('session.data.authenticated.selectedBranch.currency_type'),
        isInternalUser: isInternalUser,
        errorPromotion: ''
      });

      this.initPromotionAudience(controller, isNew);
      controller.set('promotion.campaignId', model.campaignId);
      if (model.promotionMetaData != undefined) {
        controller.set('promotion.bookingStartLowerBound', model.promotionMetaData.get('bookingStartLowerBound'));
        controller.set('promotion.bookingStartUpperBound', model.promotionMetaData.get('bookingStartUpperBound'));
        controller.set('promotion.bookingEndLowerBound', model.promotionMetaData.get('bookingEndLowerBound'));
        controller.set('promotion.bookingEndUpperBound', model.promotionMetaData.get('bookingEndUpperBound'));
        controller.set('promotion.travelStartLowerBound', model.promotionMetaData.get('travelStartLowerBound'));
        controller.set('promotion.travelStartUpperBound', model.promotionMetaData.get('travelStartUpperBound'));
        controller.set('promotion.travelEndLowerBound', model.promotionMetaData.get('travelEndLowerBound'));
        controller.set('promotion.travelEndUpperBound', model.promotionMetaData.get('travelEndUpperBound'));
        controller.set('promotion.discountLowerBound', model.promotionMetaData.get('discountLowerBound'));
        controller.set('promotion.campaignNameReadonly', model.promotionMetaData.get('campaignNameReadonly'));
      }

      if (isInternalUser) {
        this.initPromotionShoppingPath(controller, isNew);
      }

      this.initPromotionDate(controller, isNew);
      this.initPromotionName(controller, isNew);
      this.initPromotionDetails(controller, isNew, transition);
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('clone', null);
        controller.set('productId', null);
        controller.set('optionId', null);
        controller.set('campaignId', null);
      }

      this.controller.get('promotion').rollbackAttributes();
    },

    actions: {
      updatePromoActivities: function updatePromoActivities(productList) {
        var activities = [];
        productList.forEach(function (product) {
          if (product.isProductSelected) {
            var tempProduct = {};
            tempProduct.aId = product.id;
            tempProduct.offers = [];
            product.offers.forEach(function (offer) {
              if (offer.isOfferSelected) {
                var tempOffer = {};
                tempOffer.oId = offer.id;
                tempOffer.ticketTypes = [];
                offer.ticketTypes.forEach(function (ticket) {
                  if (ticket.isTicketSelected) {
                    var tempTicket = {};
                    tempTicket.ttId = ticket.id;
                    tempOffer.ticketTypes.push(tempTicket);
                  }
                });
                tempProduct.offers.push(tempOffer);
              }
            });
            activities.push(tempProduct);
          }
        });
        this.controller.set('promotion.promotionDetails.activities', activities);
      },
      savePromotion: function savePromotion() {
        var _this2 = this;

        var OVERLAP_DATES_WARNING = '1009';
        var promotion = this.controller.get('promotion');
        this.controller.set('savingPromotion', true);
        this.controller.set('errorPromotion', '');
        promotion.save().then(function (saveResponse) {
          var showPromotionWarnings = saveResponse.data.warningMessages.includes(OVERLAP_DATES_WARNING);
          _this2.controller.set('showPromotionWarnings', showPromotionWarnings);
          _this2.controller.set('readyToSave', false);
          _this2.get('notifications').success(_this2.get('i18n').t('components.alert.saveSuccess'));
          if (showPromotionWarnings) {
            _this2.get('notifications').warning(_this2.get('i18n').t('components.alert.warningMessages'));
          }
          _this2.transitionTo('promotion', saveResponse.id, {
            queryParams: {
              productId: null,
              optionId: null,
              clone: null,
              campaignId: null
            }
          });
        }).catch(function (err) {
          _this2.controller.set('errorPromotion', err);
          window.scrollTo(0, 0);
        }).finally(function () {
          _this2.controller.set('savingPromotion', false);
        });
      },
      cancelPromotionSave: function cancelPromotionSave(isNew) {
        var supplierBranchId = this.get('session.data.authenticated.selectedBranch.supplier_branch_id');
        this.controller.set('showPromotionWarnings', false);
        var lastRoute = this.get('routeHistory').getPromotionBackURL() || 'promotion-list';
        if (!isNew) {
          (0, _omniture.sendOmnitureDataOnClick)('page.LX-Supply.promotion-new.exit');
        }
        if (lastRoute === 'activity.offer-list') {
          this.transitionTo(lastRoute, this.get('routeHistory').getProductId());
        } else {
          this.transitionTo(lastRoute, supplierBranchId);
        }
      }
    }
  });
});