define('lxso/serializers/promotion', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONSerializer.extend({
    primaryKey: 'promotionId',
    normalize: function normalize(typClass, payload) {
      if (payload.fundType === 'Expedia and Supplier Funded') {
        payload.funders = [payload.expediaFund + '% Expedia', payload.supplierFund + '% Supplier'];
      } else if (payload.fundType === 'Expedia Funded') {
        payload.funders = ['Expedia'];
      } else if (payload.fundType === 'Supplier Funded') {
        payload.funders = ['Supplier'];
      }

      var audienceMap = {
        1: 'Multi-item Purchasers',
        2: 'Members',
        3: 'All Visitors'
      };
      var shoppingPathMap = {
        1: 'Standalone',
        2: 'Package'
      };
      payload.normalizedAudiences = payload.audiences.map(function (item) {
        return audienceMap[item];
      });
      payload.normalizedShoppingPath = payload.shoppingPaths.map(function (item) {
        return shoppingPathMap[item];
      });
      payload.warningMessages = payload.warningMessages;
      var productsCount = 0;
      if (payload.promotionDetails && payload.promotionDetails.activities) {
        productsCount = payload.promotionDetails.activities.length;
      }

      payload.discountTypeUI = payload.expediaFundType;
      payload.products = productsCount + ' ' + (productsCount > 1 ? 'products' : 'product');
      payload.promotionType = payload.campaignId !== -1 ? 'Campaign-' + payload.campaignName : 'Standard';
      return this._super.apply(this, arguments);
    }
  });
});