define('lxso/controllers/activity/offer', ['exports', 'lxso/mixins/paginate-source-array'], function (exports, _paginateSourceArray) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_paginateSourceArray.default, {
    isAdmissionFeesDialogOpen: false,
    isDockFeesDialogOpen: false,
    isFuelFeesDialogOpen: false,
    isInsuranceFeesDialogOpen: false,
    isAccommodationFeesDialogOpen: false,
    isTransportFeesDialogOpen: false,
    isSoloFeesDialogOpen: false,
    isHolidayFeesDialogOpen: false,
    isMiscellaneousFeesDialogOpen: false,
    isEquipmentFeesDialogOpen: false,
    isPhotographyFeesDialogOpen: false,
    isMarriageFeesDialogOpen: false,
    isLicenceFeesDialogOpen: false,
    isTastingFeesDialogOpen: false,
    isCustomsFeesDialogOpen: false,
    isVisaFeesDialogOpen: false,
    isGovernmentFeesDialogOpen: false,
    removedTicketTypes: [],
    isAlertVisible: false, // Initialize alert visibility
    alertMessage: '',
    logs: [],
    isFileUploadedSuccessfully: false,

    actions: {
      showAlert: function showAlert(data) {
        console.log('inside showAlert...');
        if (data.message) {
          this.set('alertMessage', data.message);
          this.set('logs', data.logs);
        } else {
          this.set('alertMessage', data);
        }
        this.set('isAlertVisible', true);
        this.notifyPropertyChange('isAlertVisible');
      },
      hideAlert: function hideAlert() {
        this.set('isAlertVisible', false);
        if (this.get('isFileUploadedSuccessfully')) {
          window.location.reload();
        }
      },
      openAdmissionFeesDialog: function openAdmissionFeesDialog() {
        Ember.Logger.log('Opening admissionFees dialog with data', this.get('admissionFees'));
        Ember.Logger.log('Opening admissionFees dialog sample ticketTypes123', this.get('ticketTypes'));
        this.set('isAdmissionFeesDialogOpen', true);
      },
      openDockFeesDialog: function openDockFeesDialog() {
        Ember.Logger.log('Opening DockFees dialog with data', this.get('dockFees'));
        this.set('isDockFeesDialogOpen', true);
      },
      openFuelFeesDialog: function openFuelFeesDialog() {
        Ember.Logger.log('Opening FuelFees dialog with data', this.get('fuelFees'));
        this.set('isFuelFeesDialogOpen', true);
      },
      openInsuranceFeesDialog: function openInsuranceFeesDialog() {
        Ember.Logger.log('Opening InsuranceFess dialog with data', this.get('insuranceFees'));
        this.set('isInsuranceFeesDialogOpen', true);
      },
      openAccommodationFeesDialog: function openAccommodationFeesDialog() {
        Ember.Logger.log('Opening AccommodationFees dialog with data', this.get('accommodationFees'));
        this.set('isAccommodationFeesDialogOpen', true);
      },
      openTransportFeesDialog: function openTransportFeesDialog() {
        Ember.Logger.log('Opening dialog with data', this.get('transportFees'));
        this.set('isTransportFeesDialogOpen', true);
      },
      openSoloFeesDialog: function openSoloFeesDialog() {
        Ember.Logger.log('Opening dialog with data', this.get('soloFees'));
        this.set('isSoloFeesDialogOpen', true);
      },
      openHolidayFeesDialog: function openHolidayFeesDialog() {
        Ember.Logger.log('Opening dialog with data', this.get('soloFees'));
        this.set('isHolidayFeesDialogOpen', true);
      },
      openMiscellaneousFeesDialog: function openMiscellaneousFeesDialog() {
        Ember.Logger.log('Opening dialog with data', this.get('soloFees'));
        this.set('isMiscellaneousFeesDialogOpen', true);
      },
      openEquipmentFeesDialog: function openEquipmentFeesDialog() {
        Ember.Logger.log('Opening dialog with data', this.get('equipmentFees'));
        this.set('isEquipmentFeesDialogOpen', true);
      },
      openPhotographyFeesDialog: function openPhotographyFeesDialog() {
        Ember.Logger.log('Opening dialog with data', this.get('photographyFees'));
        this.set('isPhotographyFeesDialogOpen', true);
      },
      openMarriageFeesDialog: function openMarriageFeesDialog() {
        Ember.Logger.log('Opening dialog with data', this.get('marriageFees'));
        this.set('isMarriageFeesDialogOpen', true);
      },
      openLicenceFeesDialog: function openLicenceFeesDialog() {
        Ember.Logger.log('Opening dialog with data', this.get('licenceFees'));
        this.set('isLicenceFeesDialogOpen', true);
      },
      openTastingFeesDialog: function openTastingFeesDialog() {
        Ember.Logger.log('Opening dialog with data', this.get('tastingFees'));
        this.set('isTastingFeesDialogOpen', true);
      },
      openCustomsFeesDialog: function openCustomsFeesDialog() {
        Ember.Logger.log('Opening dialog with data', this.get('customsFees'));
        this.set('isCustomsFeesDialogOpen', true);
      },
      openVisaFeesDialog: function openVisaFeesDialog() {
        Ember.Logger.log('Opening dialog with data', this.get('visaFees'));
        this.set('isVisaFeesDialogOpen', true);
      },
      openGovernmentFeesDialog: function openGovernmentFeesDialog() {
        Ember.Logger.log('Opening dialog with data', this.get('governmentFees'));
        this.set('isGovernmentFeesDialogOpen', true);
      },
      closeAdmissionFeesDialog: function closeAdmissionFeesDialog() {
        Ember.Logger.log('Closing dialog');
        this.set('isAdmissionFeesDialogOpen', false);
      },
      closeDockFeesDialog: function closeDockFeesDialog() {
        Ember.Logger.log('Closing dialog');
        this.set('isDockFeesDialogOpen', false);
      },
      closeFuelFeesDialog: function closeFuelFeesDialog() {
        Ember.Logger.log('Closing dialog');
        this.set('isFuelFeesDialogOpen', false);
      },
      closeInsuranceFeesDialog: function closeInsuranceFeesDialog() {
        Ember.Logger.log('Closing dialog');
        this.set('isInsuranceFeesDialogOpen', false);
      },
      closeAccommodationFeesDialog: function closeAccommodationFeesDialog() {
        Ember.Logger.log('Closing dialog');
        this.set('isAccommodationFeesDialogOpen', false);
      },
      closeTransportFeesDialog: function closeTransportFeesDialog() {
        Ember.Logger.log('Closing dialog');
        this.set('isTransportFeesDialogOpen', false);
      },
      closeSoloFeesDialog: function closeSoloFeesDialog() {
        Ember.Logger.log('Closing dialog');
        this.set('isSoloFeesDialogOpen', false);
      },
      closeHolidayFeesDialog: function closeHolidayFeesDialog() {
        Ember.Logger.log('Closing dialog');
        this.set('isHolidayFeesDialogOpen', false);
      },
      closeMiscellaneousFeesDialog: function closeMiscellaneousFeesDialog() {
        Ember.Logger.log('Closing dialog');
        this.set('isMiscellaneousFeesDialogOpen', false);
      },
      closeEquipmentFeesDialog: function closeEquipmentFeesDialog() {
        Ember.Logger.log('Closing dialog');
        this.set('isEquipmentFeesDialogOpen', false);
      },
      closePhotographyFeesDialog: function closePhotographyFeesDialog() {
        Ember.Logger.log('Closing dialog');
        this.set('isPhotographyFeesDialogOpen', false);
      },
      closeMarriageFeesDialog: function closeMarriageFeesDialog() {
        Ember.Logger.log('Closing dialog');
        this.set('isMarriageFeesDialogOpen', false);
      },
      closeLicenceFeesDialog: function closeLicenceFeesDialog() {
        Ember.Logger.log('Closing dialog');
        this.set('isLicenceFeesDialogOpen', false);
      },
      closeTastingFeesDialog: function closeTastingFeesDialog() {
        Ember.Logger.log('Closing dialog');
        this.set('isTastingFeesDialogOpen', false);
      },
      closeCustomsFeesDialog: function closeCustomsFeesDialog() {
        Ember.Logger.log('Closing dialog');
        this.set('isCustomsFeesDialogOpen', false);
      },
      closeVisaFeesDialog: function closeVisaFeesDialog() {
        Ember.Logger.log('Closing dialog');
        this.set('isVisaFeesDialogOpen', false);
      },
      closeGovernmentFeesDialog: function closeGovernmentFeesDialog() {
        Ember.Logger.log('Closing dialog');
        this.set('isGovernmentFeesDialogOpen', false);
      },
      saveAdmissionFees: function saveAdmissionFees(admissionFees, removedTicketTypesAdmission) {
        Ember.Logger.log('Saving admission fees removedTicketTypes', removedTicketTypesAdmission);
        Ember.Logger.log('Saving admission fees', admissionFees);

        this.set('admissionFees', admissionFees); // Updates the admissionFees property
        this.set('removedTicketTypesAdmission', removedTicketTypesAdmission); // Updates the admissionFees property
        // console.log('admissionFees in controller updatedFees', updatedFees);
        // console.log('admissionFees in controller admissionFees', admissionFees);
        this.set('isAdmissionFeesDialogOpen', false);
      },
      saveDockFees: function saveDockFees(dockFees, removedTicketTypesDock) {
        Ember.Logger.log('Saving dock fees inside controller', dockFees);
        Ember.Logger.log('Saving dock fees inside controller removedTicketTypes', removedTicketTypesDock);

        this.set('dockFees', dockFees); // Updates the admissionFees property
        this.set('removedTicketTypesDock', removedTicketTypesDock);
        this.set('isDockFeesDialogOpen', false);
      },
      saveFuelFees: function saveFuelFees(updatedFees, removedTicketTypesFuel) {
        Ember.Logger.log('Saving fuel fees', updatedFees);

        // Check if ticketTypes is included in updatedFees
        if (updatedFees.ticketTypes && updatedFees.ticketTypes.length > 0) {
          Ember.Logger.log('Ticket Types:', updatedFees.ticketTypes);
        } else {
          Ember.Logger.warn('No ticket types found!');
        }
        console.log('dockFees in controller', updatedFees);

        this.set('fuelFees', updatedFees); // Updates the admissionFees property
        this.set('removedTicketTypesFuel', removedTicketTypesFuel); // Updates the admissionFees property
        this.set('isFuelFeesDialogOpen', false);
      },
      saveInsuranceFees: function saveInsuranceFees(updatedFees, removedTicketTypesInsurance) {
        Ember.Logger.log('Saving insurance fees', updatedFees);

        // Check if ticketTypes is included in updatedFees
        if (updatedFees.ticketTypes && updatedFees.ticketTypes.length > 0) {
          Ember.Logger.log('Ticket Types:', updatedFees.ticketTypes);
        } else {
          Ember.Logger.warn('No ticket types found!');
        }

        this.set('insuranceFees', updatedFees); // Updates the admissionFees property
        this.set('removedTicketTypesInsurance', removedTicketTypesInsurance);
        this.set('isInsuranceFeesDialogOpen', false);
      },
      saveAccommodationFees: function saveAccommodationFees(updatedFees, removedTicketTypesAccommodation) {
        Ember.Logger.log('Saving accommodation fees', updatedFees);

        // Check if ticketTypes is included in updatedFees
        if (updatedFees.ticketTypes && updatedFees.ticketTypes.length > 0) {
          Ember.Logger.log('Ticket Types:', updatedFees.ticketTypes);
        } else {
          Ember.Logger.warn('No ticket types found!');
        }

        this.set('accommodationFees', updatedFees); // Updates the admissionFees property
        this.set('removedTicketTypesAccommodation', removedTicketTypesAccommodation);
        this.set('isAccommodationFeesDialogOpen', false);
      },
      saveTransportFees: function saveTransportFees(updatedFees, removedTicketTypesTransport) {
        Ember.Logger.log('Saving transport fees', updatedFees);

        // Check if ticketTypes is included in updatedFees
        if (updatedFees.ticketTypes && updatedFees.ticketTypes.length > 0) {
          Ember.Logger.log('Ticket Types:', updatedFees.ticketTypes);
        } else {
          Ember.Logger.warn('No ticket types found!');
        }

        this.set('transportFees', updatedFees); // Updates the admissionFees property
        this.set('removedTicketTypesTransport', removedTicketTypesTransport);
        this.set('isTransportFeesDialogOpen', false);
      },
      saveSoloFees: function saveSoloFees(updatedFees, removedTicketTypesSolo) {
        Ember.Logger.log('Saving solo fees', updatedFees);

        // Check if ticketTypes is included in updatedFees
        if (updatedFees.ticketTypes && updatedFees.ticketTypes.length > 0) {
          Ember.Logger.log('Ticket Types:', updatedFees.ticketTypes);
        } else {
          Ember.Logger.warn('No ticket types found!');
        }

        this.set('soloFees', updatedFees); // Updates the admissionFees property
        this.set('removedTicketTypesSolo', removedTicketTypesSolo);
        this.set('isSoloFeesDialogOpen', false);
      },
      saveHolidayFees: function saveHolidayFees(updatedFees, removedTicketTypesHoliday) {
        Ember.Logger.log('Saving holiday fees', updatedFees);

        // Check if ticketTypes is included in updatedFees
        if (updatedFees.ticketTypes && updatedFees.ticketTypes.length > 0) {
          Ember.Logger.log('Ticket Types:', updatedFees.ticketTypes);
        } else {
          Ember.Logger.warn('No ticket types found!');
        }

        this.set('holidayFees', updatedFees); // Updates the admissionFees property
        this.set('removedTicketTypesHoliday', removedTicketTypesHoliday);
        this.set('isHolidayFeesDialogOpen', false);
      },
      saveMiscellaneousFees: function saveMiscellaneousFees(updatedFees, removedTicketTypesMiscellaneous) {
        Ember.Logger.log('Saving Miscellaneous fees', updatedFees);

        // Check if ticketTypes is included in updatedFees
        if (updatedFees.ticketTypes && updatedFees.ticketTypes.length > 0) {
          Ember.Logger.log('Ticket Types:', updatedFees.ticketTypes);
        } else {
          Ember.Logger.warn('No ticket types found!');
        }

        this.set('miscellaneousFees', updatedFees); // Updates the admissionFees property
        this.set('removedTicketTypesMiscellaneous', removedTicketTypesMiscellaneous);
        this.set('isMiscellaneousFeesDialogOpen', false);
      },
      saveEquipmentFees: function saveEquipmentFees(updatedFees, removedTicketTypesEquipment) {
        Ember.Logger.log('Saving Equipment fees', updatedFees);

        // Check if ticketTypes is included in updatedFees
        if (updatedFees.ticketTypes && updatedFees.ticketTypes.length > 0) {
          Ember.Logger.log('Ticket Types:', updatedFees.ticketTypes);
        } else {
          Ember.Logger.warn('No ticket types found!');
        }

        this.set('equipmentFees', updatedFees); // Updates the admissionFees property
        this.set('removedTicketTypesEquipment', removedTicketTypesEquipment);
        this.set('isEquipmentFeesDialogOpen', false);
      },
      savePhotographyFees: function savePhotographyFees(updatedFees, removedTicketTypesPhotography) {
        Ember.Logger.log('Saving Photography fees', updatedFees);

        // Check if ticketTypes is included in updatedFees
        if (updatedFees.ticketTypes && updatedFees.ticketTypes.length > 0) {
          Ember.Logger.log('Ticket Types:', updatedFees.ticketTypes);
        } else {
          Ember.Logger.warn('No ticket types found!');
        }

        this.set('photographyFees', updatedFees); // Updates the admissionFees property
        this.set('removedTicketTypesPhotography', removedTicketTypesPhotography);
        this.set('isPhotographyFeesDialogOpen', false);
      },
      saveMarriageFees: function saveMarriageFees(updatedFees, removedTicketTypesMarriage) {
        Ember.Logger.log('Saving Photography fees', updatedFees);

        // Check if ticketTypes is included in updatedFees
        if (updatedFees.ticketTypes && updatedFees.ticketTypes.length > 0) {
          Ember.Logger.log('Ticket Types:', updatedFees.ticketTypes);
        } else {
          Ember.Logger.warn('No ticket types found!');
        }

        this.set('marriageFees', updatedFees); // Updates the admissionFees property
        this.set('removedTicketTypesMarriage', removedTicketTypesMarriage);
        this.set('isMarriageFeesDialogOpen', false);
      },
      saveLicenceFees: function saveLicenceFees(updatedFees, removedTicketTypesLicence) {
        Ember.Logger.log('Saving Licence fees', updatedFees);

        // Check if ticketTypes is included in updatedFees
        if (updatedFees.ticketTypes && updatedFees.ticketTypes.length > 0) {
          Ember.Logger.log('Ticket Types:', updatedFees.ticketTypes);
        } else {
          Ember.Logger.warn('No ticket types found!');
        }

        this.set('licenceFees', updatedFees); // Updates the admissionFees property
        this.set('removedTicketTypesLicence', removedTicketTypesLicence);
        this.set('isLicenceFeesDialogOpen', false);
      },
      saveTastingFees: function saveTastingFees(updatedFees, removedTicketTypesTasting) {
        Ember.Logger.log('Saving Tasting fees', updatedFees);

        // Check if ticketTypes is included in updatedFees
        if (updatedFees.ticketTypes && updatedFees.ticketTypes.length > 0) {
          Ember.Logger.log('Ticket Types:', updatedFees.ticketTypes);
        } else {
          Ember.Logger.warn('No ticket types found!');
        }

        this.set('tastingFees', updatedFees); // Updates the admissionFees property
        this.set('removedTicketTypesTasting', removedTicketTypesTasting);
        this.set('isTastingFeesDialogOpen', false);
      },
      saveCustomsFees: function saveCustomsFees(updatedFees, removedTicketTypesCustoms) {
        Ember.Logger.log('Saving Customs fees', updatedFees);

        // Check if ticketTypes is included in updatedFees
        if (updatedFees.ticketTypes && updatedFees.ticketTypes.length > 0) {
          Ember.Logger.log('Ticket Types:', updatedFees.ticketTypes);
        } else {
          Ember.Logger.warn('No ticket types found!');
        }

        this.set('customsFees', updatedFees); // Updates the admissionFees property
        this.set('removedTicketTypesCustoms', removedTicketTypesCustoms);
        this.set('isCustomsFeesDialogOpen', false);
      },
      saveVisaFees: function saveVisaFees(updatedFees, removedTicketTypesVisa) {
        Ember.Logger.log('Saving Visa fees', updatedFees);

        // Check if ticketTypes is included in updatedFees
        if (updatedFees.ticketTypes && updatedFees.ticketTypes.length > 0) {
          Ember.Logger.log('Ticket Types:', updatedFees.ticketTypes);
        } else {
          Ember.Logger.warn('No ticket types found!');
        }

        this.set('visaFees', updatedFees); // Updates the admissionFees property
        this.set('removedTicketTypesVisa', removedTicketTypesVisa);
        this.set('isVisaFeesDialogOpen', false);
      },
      saveGovernmentFees: function saveGovernmentFees(updatedFees, removedTicketTypesGovernment) {
        Ember.Logger.log('Saving Government Fees', updatedFees);

        // Check if ticketTypes is included in updatedFees
        if (updatedFees.ticketTypes && updatedFees.ticketTypes.length > 0) {
          Ember.Logger.log('Ticket Types:', updatedFees.ticketTypes);
        } else {
          Ember.Logger.warn('No ticket types found!');
        }

        this.set('governmentFees', updatedFees); // Updates the admissionFees property
        this.set('removedTicketTypesGovernment', removedTicketTypesGovernment);
        this.set('isGovernmentFeesDialogOpen', false);
      },


      // Call this action to save all Mandatory fees to the route
      saveAllMandatoryFees: function saveAllMandatoryFees() {
        var admissionFees = this.get('admissionFees');
        var dockFees = this.get('dockFees');
        var fuelFees = this.get('fuelFees');
        var insuranceFees = this.get('insuranceFees');
        var accommodationFees = this.get('accommodationFees');
        var transportFees = this.get('transportFees');
        var soloFees = this.get('soloFees');
        var holidayFees = this.get('holidayFees');
        var miscellaneousFees = this.get('miscellaneousFees');

        // Define all removedTicketTypes variables in an array
        var removedTicketTypesArray = [this.get('removedTicketTypesAdmission'), this.get('removedTicketTypesDock'), this.get('removedTicketTypesFuel'), this.get('removedTicketTypesAccommodation'), this.get('removedTicketTypesInsurance'), this.get('removedTicketTypesTransport'), this.get('removedTicketTypesSolo'), this.get('removedTicketTypesHoliday'), this.get('removedTicketTypesMiscellaneous')];

        // Collect all the fees in an array and pass them to the route

        // Initialize the combined fees array
        var combinedFees = [];

        // If admissionFees does NOT have a ticketTypes array, add it to combinedFees
        if (admissionFees.length && !admissionFees[0].ticketTypes) {
          combinedFees = combinedFees.concat(admissionFees);
          console.log('inside admissionFees....');
        }

        // If dockFees does NOT have a ticketTypes array, add it to combinedFees
        if (dockFees.length && !dockFees[0].ticketTypes) {
          combinedFees = combinedFees.concat(dockFees);
          console.log('inside dockFees....');
        }

        // If dockFees does NOT have a ticketTypes array, add it to combinedFees
        if (fuelFees.length && !fuelFees[0].ticketTypes) {
          combinedFees = combinedFees.concat(fuelFees);
          console.log('inside dockFees....');
        }

        // If dockFees does NOT have a ticketTypes array, add it to combinedFees
        if (insuranceFees.length && !insuranceFees[0].ticketTypes) {
          combinedFees = combinedFees.concat(insuranceFees);
          console.log('inside insuranceFees....');
        }

        // If accommodationFees does NOT have a ticketTypes array, add it to combinedFees
        if (accommodationFees.length && !accommodationFees[0].ticketTypes) {
          combinedFees = combinedFees.concat(accommodationFees);
          console.log('inside accommodationFees....');
        }

        // If accommodationFees does NOT have a ticketTypes array, add it to combinedFees
        if (transportFees.length && !transportFees[0].ticketTypes) {
          combinedFees = combinedFees.concat(transportFees);
          console.log('inside transportFees....');
        }

        // If accommodationFees does NOT have a ticketTypes array, add it to combinedFees
        if (soloFees.length && !soloFees[0].ticketTypes) {
          combinedFees = combinedFees.concat(soloFees);
          console.log('inside soloFees....');
        }

        // If accommodationFees does NOT have a ticketTypes array, add it to combinedFees
        if (holidayFees.length && !holidayFees[0].ticketTypes) {
          combinedFees = combinedFees.concat(holidayFees);
          console.log('inside holidayFees....');
        }

        // If accommodationFees does NOT have a ticketTypes array, add it to combinedFees
        if (miscellaneousFees.length && !miscellaneousFees[0].ticketTypes) {
          combinedFees = combinedFees.concat(miscellaneousFees);
          console.log('inside miscellaneousFees....');
        }

        // Iterate over removedTicketTypesArray to add each non-empty removed ticket type
        removedTicketTypesArray.forEach(function (removedTickets) {
          if (removedTickets && removedTickets.length) {
            console.log('removedTickets inside removedTicketTypesArray foreach:', removedTickets);
            combinedFees = combinedFees.concat(removedTickets);
          }
        });

        this.send('saveMandatoryFees', combinedFees); // Call the route's action
      },


      // Call this action to save all Mandatory fees to the route
      saveAllOptionalFees: function saveAllOptionalFees() {
        var equipmentFees = this.get('equipmentFees');
        var photographyFees = this.get('photographyFees');
        var marriageFees = this.get('marriageFees');
        var licenceFees = this.get('licenceFees');
        var tastingFees = this.get('tastingFees');
        var customsFees = this.get('customsFees');
        var visaFees = this.get('visaFees');
        var governmentFees = this.get('governmentFees');

        var removedTicketTypesArrayOptional = [this.get('removedTicketTypesEquipment'), this.get('removedTicketTypesPhotography'), this.get('removedTicketTypesMarriage'), this.get('removedTicketTypesLicence'), this.get('removedTicketTypesTasting'), this.get('removedTicketTypesCustoms'), this.get('removedTicketTypesVisa'), this.get('removedTicketTypesGovernment')];

        // Collect all the fees in an array and pass them to the route

        // Initialize the combined fees array
        var combinedFees = [];

        // If equipmentFees does NOT have a ticketTypes array, add it to combinedFees
        if (equipmentFees.length && !equipmentFees[0].ticketTypes) {
          combinedFees = combinedFees.concat(equipmentFees);
          console.log('inside equipmentFees....');
        }

        // If photographyFees does NOT have a ticketTypes array, add it to combinedFees
        if (photographyFees.length && !photographyFees[0].ticketTypes) {
          combinedFees = combinedFees.concat(photographyFees);
          console.log('inside photographyFees....');
        }

        // If marriageFees does NOT have a ticketTypes array, add it to combinedFees
        if (marriageFees.length && !marriageFees[0].ticketTypes) {
          combinedFees = combinedFees.concat(marriageFees);
          console.log('inside marriageFees....');
        }

        // If licenceFees does NOT have a ticketTypes array, add it to combinedFees
        if (licenceFees.length && !licenceFees[0].ticketTypes) {
          combinedFees = combinedFees.concat(licenceFees);
          console.log('inside licenceFees....');
        }

        // If tastingFees does NOT have a ticketTypes array, add it to combinedFees
        if (tastingFees.length && !tastingFees[0].ticketTypes) {
          combinedFees = combinedFees.concat(tastingFees);
          console.log('inside tastingFees....');
        }

        // If customsFees does NOT have a ticketTypes array, add it to combinedFees
        if (customsFees.length && !customsFees[0].ticketTypes) {
          combinedFees = combinedFees.concat(customsFees);
          console.log('inside customsFees....');
        }

        // If visaFees does NOT have a ticketTypes array, add it to combinedFees
        if (visaFees.length && !visaFees[0].ticketTypes) {
          combinedFees = combinedFees.concat(visaFees);
          console.log('inside visaFees....');
        }

        // If governmentFees does NOT have a ticketTypes array, add it to combinedFees
        if (governmentFees.length && !governmentFees[0].ticketTypes) {
          combinedFees = combinedFees.concat(governmentFees);
          console.log('inside governmentFees....');
        }

        // Iterate over removedTicketTypesArray to add each non-empty removed ticket type
        removedTicketTypesArrayOptional.forEach(function (removedTickets) {
          if (removedTickets && removedTickets.length) {
            console.log('removedTickets inside removedTicketTypesArray foreach:', removedTickets);
            combinedFees = combinedFees.concat(removedTickets);
          }
        });

        this.send('saveOptionalFees', combinedFees); // Call the route's action
      }
    }
  });
});